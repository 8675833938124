import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { combineLatest, firstValueFrom } from "rxjs";
import { Button, ListGroup, Modal } from "flowbite-react";
import { auth } from "../../firebase/firebase";
import { HiPlus } from "react-icons/hi";
import { Product } from "../../models/product";
import ProductService from "../../services/product.service";
import { IonButton } from "@ionic/react";

const SelectProductButtonAndModal: FC<{
  buttonCopy?: string | undefined;
  selection: (product: Product) => void;
  storeIds?: string[]; // If storeIds is undefined, then all products for the brand will be fetched
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  buttonCopy,
  selection,
  storeIds,
  setErrorData,
  setErrorModalIsOpen,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const brand = useBrandContext();

  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (isOpen) {
      if (storeIds) {
        const subscription = combineLatest([
          ProductService.getAllByStoreIds(auth.tenantId, brand.id, storeIds),
        ]).subscribe({
          next: ([products]) => {
            setProducts(products);
            // setIsLoading(false);
          },
          error: (err) => {
            // setIsLoading(false);
            setErrorData(err);
            setErrorModalIsOpen(true);
            throw err;
          },
          complete: () => console.info("complete"),
        });

        return () => subscription.unsubscribe();
      } else {
        const subscription = combineLatest([
          ProductService.getAllByBrand(auth.tenantId, brand.id),
        ]).subscribe({
          next: ([products]) => {
            setProducts(products);
            // setIsLoading(false);
          },
          error: (err) => {
            // setIsLoading(false);
            setErrorData(err);
            setErrorModalIsOpen(true);
            throw err;
          },
          complete: () => console.info("complete"),
        });

        return () => subscription.unsubscribe();
      }
    }

    return undefined;
  }, [isOpen]);

  function onSelect(product: Product) {
    selection(product);
    setIsOpen(false);
  }

  return (
    <>
      <IonButton size="small" color="success" onClick={() => setIsOpen(true)}>
        <HiPlus className="mr-1 h-3 w-3" />
        {buttonCopy ? buttonCopy : "Select a Product"}
      </IonButton>

      <Modal onClose={() => setIsOpen(false)} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Select a Product</strong>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "60vh" }}>
          {products && products.length > 0 ? (
            <div className="flex justify-center">
              <ListGroup className="w-full">
                {products.map((product) => (
                  <ListGroup.Item
                    key={product.id}
                    onClick={() => onSelect(product)}
                  >
                    <img
                      src={product.productImageUrl}
                      className="w-32 aspect-square mr-4"
                    />
                    {product.productName}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          ) : (
            <p>No products found.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <IonButton
            size="small"
            color="danger"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </IonButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectProductButtonAndModal;
