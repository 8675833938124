import {
  DocumentData,
  Query,
  QueryConstraint,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { ExperienceProduct } from "../models/experience-product";

function generateId() {
  return doc(collection(db, "experienceProducts")).id;
}

function getOne(id: string): Observable<ExperienceProduct> {
  if (!id) return of(undefined);
  const docRef = doc(db, `experienceProducts/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAllByExperience(
  tenantId: string,
  brandId: string,
  experienceId: string
): Observable<ExperienceProduct[]> {
  const collectionRef = collection(db, "experienceProducts");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("experienceId", "==", experienceId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) =>
          (a.experienceProductOrder ? a.experienceProductOrder : 0) -
          (b.experienceProductOrder ? b.experienceProductOrder : 0)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

async function saveOne(
  experienceProduct: Partial<ExperienceProduct>,
  isNew: boolean
): Promise<any> {
  try {
    if (isNew) {
      experienceProduct.createdDate = serverTimestamp();
      experienceProduct.createdByUserAccountId = auth.currentUser.uid;
    } else {
      experienceProduct.updatedDate = serverTimestamp();
      experienceProduct.updatedByUserAccountId = auth.currentUser.uid;
    }

    if (experienceProduct.deleted == undefined)
      experienceProduct.deleted = false;

    experienceProduct =
      DataTransformationService.convertDatesToFirestoreTimestamp(
        experienceProduct
      );

    let docRef = doc(db, `experienceProducts/${experienceProduct.id}`);
    await setDoc(docRef, experienceProduct, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(experienceProductId: string): Promise<any> {
  try {
    let docRef = doc(db, `experienceProducts/${experienceProductId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

const ExperienceProductService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllByExperience,
};

export default ExperienceProductService;
