import { IonAlert, IonButton } from "@ionic/react";
import { error } from "console";
import { Button, Modal } from "flowbite-react";
import { FC } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

const ErrorModal: FC<{
  errorData: Error | undefined;
  errorModalIsOpen: boolean;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ errorData, errorModalIsOpen, setErrorModalIsOpen }) => {
  return (
    <>
      <IonAlert
        isOpen={errorModalIsOpen}
        header={errorData?.name || "Error"}
        // subHeader="A Sub Header Is Optional"
        message={errorData?.message}
        buttons={["Ok"]}
        onDidDismiss={() => setErrorModalIsOpen(false)}
      ></IonAlert>
    </>
  );
};

export default ErrorModal;
