import {
  IonBadge,
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  useIonRouter,
} from "@ionic/react";

import { Redirect, Route, useLocation, useParams } from "react-router";
import { listOutline, peopleCircleOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useAuth } from "../firebase/auth";
import BrandsPage from "../pages/tenant/Brands";
import TenantUsersPage from "../pages/tenant/TenantUsers";
import UserAccountTenantPermissionService from "../services/user-account-tenant-permission.service";
import UserAccountBrandPermissionService from "../services/user-account-brand-permission.service";
import { combineLatest } from "rxjs";
import { UserAccountTenantPermission } from "../models/user-account-tenant-permission";
import { UserAccountBrandPermission } from "../models/user-account-brand-permission";
import { UserAccountTenantPermissionsContext } from "../context/userAccountTenantPermissions.context";
import { UserAccountBrandPermissionsContext } from "../context/userAccountBrandPermissions.context";
import ErrorAlert from "../components/ErrorAlert";
import SpinnerCentered from "../components/SpinnerCentered";

interface MenuItem {
  url: string;
  icon: string;
  title: string;
  description?: string;
}

const TenantMenu: React.FC = () => {
  const location = useLocation();
  const navigation = useIonRouter();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [tenantMenuItems, setTenantMenuItems] = useState<MenuItem[]>([]);

  // Error Message Handling
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);

  const [userAccountTenantPermissions, setUserAccountTenantPermissions] =
    useState<UserAccountTenantPermission[] | undefined>(undefined);

  const [userAccountBrandPermissions, setUserAccountBrandPermissions] =
    useState<UserAccountBrandPermission[] | undefined>(undefined);

  const auth = useAuth();

  useEffect(() => {
    //redirect if user not logged in.
    if (!auth.isLoading && (!auth?.authUser || auth?.authUser?.isAnonymous)) {
      navigation.push("/", "root", "replace");
    } else if (!auth.isLoading && auth.authUser) {
      const subscription = combineLatest([
        UserAccountTenantPermissionService.getAllForUserAccount(
          auth.authUser.tenantId,
          auth.authUser.uid
        ),
        UserAccountBrandPermissionService.getAllForUserAccount(
          auth.authUser.tenantId,
          auth.authUser.uid
        ),
      ]).subscribe({
        next: ([userAccountTenantPermissions, userAccountBrandPermissions]) => {
          setUserAccountTenantPermissions(userAccountTenantPermissions);
          setUserAccountBrandPermissions(userAccountBrandPermissions);
          if (userAccountTenantPermissions.length > 0) {
            setTenantMenuItems([
              {
                title: "Brands",
                description: "The companies using Drawbridge.",
                url: "/tenant/brands",
                icon: listOutline,
              },
              {
                title: "System Admin Users",
                description: "Manage the admin users on this tenant.",
                url: "/tenant/users",
                icon: peopleCircleOutline,
              },
            ]);
          } else {
            setTenantMenuItems([
              {
                title: "Brands",
                description: "The companies using Drawbridge.",
                url: "/tenant/brands",
                icon: listOutline,
              },
            ]);
          }

          setIsLoading(false);
        },
        error: (e) => {
          setIsLoading(false);
          setErrorData(e);
          setErrorModalIsOpen(true);
          throw e;
        },
        complete: () => console.info("complete"),
      });

      return () => subscription.unsubscribe();
    } else {
      return;
    }
  }, [auth]);

  return (
    <IonPage>
      {isLoading ? (
        <SpinnerCentered />
      ) : (
        <UserAccountTenantPermissionsContext.Provider
          value={userAccountTenantPermissions}
        >
          <UserAccountBrandPermissionsContext.Provider
            value={userAccountBrandPermissions}
          >
            <IonSplitPane contentId="main">
              <IonMenu contentId="main" type="overlay">
                <IonContent>
                  <img
                    alt="Drawbridge&trade; Dashboard Manager"
                    src="/drawbridge-assets/drawbridge-dashboard-manager-logo.png"
                    className="ion-padding"
                  />
                  <IonList id="inbox-list">
                    {tenantMenuItems.map((menuItem, index) => {
                      return (
                        <IonMenuToggle key={index} autoHide={false}>
                          <IonItem
                            className={
                              location.pathname.includes(menuItem.url)
                                ? "selected"
                                : ""
                            }
                            routerLink={menuItem.url}
                            routerDirection="none"
                            lines="inset"
                            detail={false}
                          >
                            <IonIcon
                              aria-hidden="true"
                              slot="start"
                              icon={menuItem.icon}
                            />
                            <IonLabel className="ion-word-wrap">
                              <h2>{menuItem.title}</h2>
                              <p>{menuItem.description}</p>
                            </IonLabel>
                          </IonItem>
                        </IonMenuToggle>
                      );
                    })}
                  </IonList>
                </IonContent>
                <IonFooter className="ion-text-center">
                  <div className="ion-text-center">
                    <IonButton
                      size="small"
                      fill="clear"
                      onClick={() =>
                        navigation.push("/logout", "root", "replace")
                      }
                    >
                      Logout
                    </IonButton>
                    <p className="text-xs">Copyright 2024 Drawbridge Labs</p>
                  </div>
                  {/* {userAccountTenantPermissions &&
                    userAccountTenantPermissions[0].admin && (
                      <IonBadge color="primary">Tenant Admin</IonBadge>
                    )} */}
                </IonFooter>
              </IonMenu>

              <IonRouterOutlet id="main">
                <Route path="/tenant/brands" component={BrandsPage} />
                <Route path="/tenant/users" component={TenantUsersPage} />

                {/* Redirect Default */}
                <Redirect exact path="/tenant" to="/tenant/brands" />
              </IonRouterOutlet>
            </IonSplitPane>
          </UserAccountBrandPermissionsContext.Provider>
        </UserAccountTenantPermissionsContext.Provider>
      )}
      <ErrorAlert
        errorData={errorData}
        isOpen={errorModalIsOpen}
        setIsOpen={setErrorModalIsOpen}
      />
    </IonPage>
  );
};

export default TenantMenu;
