import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { useForm } from "react-hook-form";
import { firstValueFrom } from "rxjs";

import { auth } from "../../firebase/firebase";
import { HiPencilAlt, HiPlus } from "react-icons/hi";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { TestAccess } from "../../models/test-access";
import TestAccessService from "../../services/test-access.service";
import ExperiencePage from "../../pages/brand/Experience";
import { Spinner } from "flowbite-react";

const EditTestAccessButtonAndModal: FC<{
  testAccessId: string | undefined;
  experienceId: string;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttonCopy?: string;
}> = ({
  testAccessId,
  experienceId,
  setErrorData,
  setErrorModalIsOpen,
  buttonCopy,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      id: null,
      tenantId: null,
      brandId: null,
      experienceId: null,
      testAccessName: null,
      testAccessStartDate: null,
      testAccessEndDate: null,
    },
  });
  const brand = useBrandContext();

  useEffect(() => {
    if (isOpen) {
      if (testAccessId) {
        firstValueFrom(TestAccessService.getOne(testAccessId))
          .then((set) => {
            updateFormValues(set);
          })
          .catch((err) => {
            setErrorData(err);
            setErrorModalIsOpen(true);
            throw err;
          });
      } else {
        const newTestAccess: Partial<TestAccess> = {
          id: TestAccessService.generateId(),
          tenantId: auth.tenantId,
          brandId: brand.id,
          experienceId: experienceId,
        };
        updateFormValues(newTestAccess);
      }
    }
  }, [isOpen, testAccessId]);

  function updateFormValues(testAccess: Partial<TestAccess>) {
    setValue("id", testAccess.id);
    setValue("tenantId", testAccess.tenantId);
    setValue("brandId", testAccess.brandId);
    setValue("experienceId", testAccess.experienceId);
    setValue("testAccessName", testAccess.testAccessName || null);
    setValue("testAccessStartDate", testAccess.testAccessStartDate || null);
    setValue("testAccessEndDate", testAccess.testAccessEndDate || null);
  }

  async function onSubmit(formData: any) {
    try {
      setIsSaving(true);

      await TestAccessService.saveOne(formData, testAccessId ? false : true);

      setIsSaving(false);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      {testAccessId ? (
        <IonButton size="small" color="warning" onClick={() => setIsOpen(true)}>
          <HiPencilAlt className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "Edit Test Access"}
        </IonButton>
      ) : (
        <IonButton size="small" color="success" onClick={() => setIsOpen(true)}>
          <HiPlus className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "New Test Access"}
        </IonButton>
      )}
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {testAccessId ? "Edit Test Access" : "New Test Access"}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList>
              <IonItem>
                <IonInput
                  label="Name"
                  placeholder=""
                  {...register("testAccessName")}
                  type="text"
                ></IonInput>
              </IonItem>

              {/* <IonItem>
              <IonLabel>Start Date</IonLabel>
              <IonDatetime {...register("testAccessStartDate")}></IonDatetime>
            </IonItem> */}

              {/* <IonItem>
              <IonInput
                label="End Date"
                {...register("testAccessEndDate")}
              ></IonInput>
            </IonItem> */}
            </IonList>
            <IonButton type="submit" color="primary" disabled={isSaving}>
              Save
              {isSaving && (
                <span className="ml-2">
                  <Spinner color={"success"} size="sm" aria-label="Saving..." />
                </span>
              )}
            </IonButton>
          </form>
        </IonContent>
      </IonModal>
      {/* <Modal onClose={() => setIsOpen(false)} show={isOpen}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            {setId ? <strong>Edit Set</strong> : <strong>New Set</strong>}
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "60vh" }}>
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
              <div className="lg:col-span-2">
                <Label htmlFor="setName">Set name</Label>
                <TextInput {...register("setName")} className="mt-1" />
              </div>
              <div className="lg:col-span-2">
                <Label htmlFor="setDescription">Set Description</Label>
                <Textarea {...register("setDescription")} className="mt-1" />
              </div>
              <div className="lg:col-span-1">
                <Label htmlFor="maxProductCount">Maximum Product Count</Label>
                <TextInput
                  type="number"
                  {...register("maxProductCount")}
                  className="mt-1"
                />
              </div>
              <div className="lg:col-span-1">
                <Label htmlFor="maxVideoCount">Maximum Video Count</Label>
                <TextInput
                  type="number"
                  {...register("maxVideoCount")}
                  className="mt-1"
                />
              </div>
              <div className="lg:col-span-1">
                <Label htmlFor="maxQuestionCount">Maximum Question Count</Label>
                <TextInput
                  type="number"
                  {...register("maxQuestionCount")}
                  className="mt-1"
                />
              </div>
              <div className="lg:col-span-1"></div>

              <div className="lg:col-span-2">
                <Label className="block">Set Image</Label>
                {watch("setImageUrl") && (
                  <img src={watch("setImageUrl")} alt="" className="mb-4" />
                )}
                <FileUploadInput upload={uploadImage} isUploading={isSaving} />
                {watch("setImageUrl") && (
                  <IonButton
                    size="small"
                    className="inline-block"
                    color="danger"
                    onClick={() => {
                      uploadImage(null);
                    }}
                  >
                    <HiTrash className="mr-1 h-3 w-3" />
                    Remove
                  </IonButton>
                )}
              </div>

              <div className="lg:col-span-2">
                <Label htmlFor="setComponentName">
                  Component Name (to be configured by technical support)
                </Label>
                <TextInput {...register("setComponentName")} className="mt-1" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <IonButton type="submit" color="primary" disabled={isSaving}>
              Save
              {isSaving && (
                <span className="ml-2">
                  <Spinner color={"success"} size="sm" aria-label="Saving..." />
                </span>
              )}
            </IonButton>
          </Modal.Footer>
        </form>
      </Modal> */}
    </>
  );
};

export default EditTestAccessButtonAndModal;
