import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router";
import BrandMenu from "./navigation/BrandMenu";

// Import Tailwind and Flowbite
import "./index.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { AuthUserProvider } from "./firebase/auth";
import SignUpPage from "./pages/authentication/sign-up";
import SignInPage from "./pages/authentication/sign-in";
import ForgotPasswordPage from "./pages/authentication/forgot-password";
import ResetPasswordPage from "./pages/authentication/reset-password";
import LogoutPage from "./pages/authentication/logout";
import TenantMenu from "./navigation/TenantMenu";

setupIonicReact({
  mode: "ios", //default to iOS style
});

const App: React.FC = () => {
  return (
    <AuthUserProvider>
      <IonApp>
        <IonReactRouter>
          {/* Without Menu */}
          <Route exact path="/" component={SignInPage} />
          <Route exact path="/authentication/sign-up" component={SignUpPage} />
          <Route
            exact
            path="/authentication/forgot-password"
            component={ForgotPasswordPage}
          />
          <Route
            exact
            path="/authentication/reset-password"
            component={ResetPasswordPage}
          />
          <Route exact path="/logout" component={LogoutPage} />

          {/* Brand-specific Navigation */}
          <Route path="/brand/:brandId" component={BrandMenu} />

          {/* Tenant-level navigation */}
          <Route path="/tenant" component={TenantMenu} />
        </IonReactRouter>
      </IonApp>
    </AuthUserProvider>
  );
};

export default App;
