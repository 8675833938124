import {
  DocumentData,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db, functionsUrl } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { Question } from "../models/question";
import axios from "axios";

function generateId() {
  return doc(collection(db, "questions")).id;
}

function getOne(id: string): Observable<Question> {
  if (!id) return of(undefined);
  const docRef = doc(db, `questions/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAllByBrand(
  tenantId: string,
  brandId: string
): Observable<Question[]> {
  const collectionRef = collection(db, "questions");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort((a, b) => a.questionText.localeCompare(b.questionText));
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

function getAllByIds(
  tenantId: string,
  brandId: string,
  questionIds: string[]
): Observable<Question[]> {
  //Only query with valid questionIds array
  if (!questionIds || !Array.isArray(questionIds) || questionIds.length === 0)
    return of([]);

  //Limit query due to firebase limitation
  if (questionIds.length > 10)
    throw new Error("Currently limited to ten questions.");

  const collectionRef = collection(db, "questions");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("id", "in", questionIds),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) =>
          (a.createdDate && a.createdDate instanceof Timestamp
            ? a.createdDate.seconds
            : 99999999999) -
          (b.createdDate && b.createdDate instanceof Timestamp
            ? b.createdDate.seconds
            : 99999999999)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

async function saveOne(
  question: Partial<Question>,
  isNew: boolean
): Promise<any> {
  try {
    if (isNew) {
      question.createdDate = serverTimestamp();
      question.createdByUserAccountId = auth.currentUser.uid;
    } else {
      question.updatedDate = serverTimestamp();
      question.updatedByUserAccountId = auth.currentUser.uid;
    }

    if (question.deleted == undefined) question.deleted = false;

    question =
      DataTransformationService.convertDatesToFirestoreTimestamp(question);

    let docRef = doc(db, `questions/${question.id}`);
    await setDoc(docRef, question, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(questionId: string): Promise<any> {
  try {
    let docRef = doc(db, `questions/${questionId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

// Uplaods file and returns the file url
async function uploadQuestionImage(
  brandId: string,
  questionId: string,
  file: File
): Promise<string> {
  try {
    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //get signed url from firebase function
    const apiResponse = await client.post(
      `brand/${brandId}/questions/r2-signed-url`,
      {
        questionId: questionId,
        fileName: file.name,
      }
    );

    const signedUrlAndFileUrl: { signedUrl: string; fileUrl: string } =
      apiResponse?.data;
    if (!signedUrlAndFileUrl)
      throw new Error("Error getting signed url during upload process.");

    //upload image to r2
    await axios.put(signedUrlAndFileUrl.signedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    return signedUrlAndFileUrl.fileUrl;
  } catch (err) {
    throw err;
  }
}

const QuestionService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllByBrand,
  getAllByIds,
  uploadQuestionImage,
};

export default QuestionService;
