import {
  DocumentData,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { Video } from "../models/video";

function generateId() {
  return doc(collection(db, "videos")).id;
}

function getOne(id: string): Observable<Video> {
  if (!id) return of(undefined);
  const docRef = doc(db, `videos/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAllByBrand(tenantId: string, brandId: string): Observable<Video[]> {
  const collectionRef = collection(db, "videos");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort((a, b) => a.videoName.localeCompare(b.videoName));
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

function getAllByIds(
  tenantId: string,
  brandId: string,
  videoIds: string[]
): Observable<Video[]> {
  //Only query with valid videoIds array
  if (!videoIds || !Array.isArray(videoIds) || videoIds.length === 0)
    return of([]);

  //Limit query due to firebase limitation
  if (videoIds.length > 10) throw new Error("Currently limited to ten videos.");

  const collectionRef = collection(db, "videos");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("id", "in", videoIds),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) =>
          (a.createdDate && a.createdDate instanceof Timestamp
            ? a.createdDate.seconds
            : 99999999999) -
          (b.createdDate && b.createdDate instanceof Timestamp
            ? b.createdDate.seconds
            : 99999999999)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

async function saveOne(video: Partial<Video>, isNew: boolean): Promise<any> {
  try {
    if (isNew) {
      video.createdDate = serverTimestamp();
      video.createdByUserAccountId = auth.currentUser.uid;
    } else {
      video.updatedDate = serverTimestamp();
      video.updatedByUserAccountId = auth.currentUser.uid;
    }

    if (video.deleted == undefined) video.deleted = false;

    video = DataTransformationService.convertDatesToFirestoreTimestamp(video);

    let docRef = doc(db, `videos/${video.id}`);
    await setDoc(docRef, video, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(videoId: string): Promise<any> {
  try {
    let docRef = doc(db, `videos/${videoId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

const VideoService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllByBrand,
  getAllByIds,
};

export default VideoService;
