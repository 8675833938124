/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Spinner, Table } from "flowbite-react";
import { useEffect, type FC, useState } from "react";

import { combineLatest, map, switchMap } from "rxjs";
import ErrorModal from "../../components/modals/error-modal";
import { useBrandContext } from "../../context/brand.context";
import { auth } from "../../firebase/firebase";
import EventService from "../../services/event.service";
import { Brand } from "../../models/brand";
import { Event } from "../../models/event";
import { useParams } from "react-router";
import { Experience } from "../../models/experience";
import ExperienceService from "../../services/experience.service";
import { HiChevronDoubleRight } from "react-icons/hi";
import { Group } from "../../models/group";
import GroupService from "../../services/group.service";
import EditEventButtonAndModal from "../../components/modals/edit-event-button-and-modal";
import StoreService from "../../services/store.service";
import { Store } from "../../models/store";
import { Link } from "react-router-dom";
import DataTransformationService from "../../services/data-transformation.service";
import EditExperienceButtonAndModal from "../../components/modals/edit-experience-button-and-modal";
import DeleteExperienceButtonAndModal from "../../components/modals/delete-experience-button-and-modal";
import SelectStoreButtonAndModal from "../../components/modals/select-store-button-and-modal";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import Breadcrumbs from "../../components/Breadcrumbs";
import SpinnerCentered from "../../components/SpinnerCentered";

const EventPage: FC = function () {
  const { eventId } = useParams<{ eventId: string }>();
  const brand = useBrandContext();

  // Error Message Handling
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [event, setEvent] = useState<Event | undefined>(undefined);
  const [group, setGroup] = useState<Group | undefined>(undefined);
  const [stores, setStores] = useState<Store[]>([]);

  useEffect(() => {
    const subscription = combineLatest([EventService.getOne(String(eventId))])
      .pipe(
        switchMap(([event]) => {
          return combineLatest([
            GroupService.getOne(event.groupId),
            StoreService.getAllByIds(auth.tenantId, brand.id, event.storeIds),
          ]).pipe(
            map(([group, stores]) => ({
              event,
              stores,
              group,
            }))
          );
        })
      )
      .subscribe({
        next: ({ event, stores, group }) => {
          setEvent(event);
          setStores(stores);
          setGroup(group);
          setIsLoading(false);
        },
        error: (err) => {
          setIsLoading(false);
          setErrorData(err);
          setErrorModalIsOpen(true);
          throw err;
        },
        complete: () => console.info("complete"),
      });

    return () => subscription.unsubscribe();
  }, []);

  async function addStoreToEvent(storeId: string) {
    try {
      setIsLoading(true);
      const storeIds: string[] = event.storeIds || [];
      if (!storeIds.includes(storeId)) {
        storeIds.push(storeId);
        await EventService.saveOne({ id: event.id, storeIds: storeIds }, false);
      } else {
        throw new Error("Store already exists in this event.");
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>
            {group?.groupName}: {event?.eventName}
          </IonTitle>
          <IonButtons slot="end">
            <EditEventButtonAndModal
              eventId={eventId}
              group={group}
              setErrorData={setErrorData}
              setErrorModalIsOpen={setErrorModalIsOpen}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              {group?.groupName}: {event?.eventName}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        {isLoading ? (
          <SpinnerCentered />
        ) : (
          <>
            <Breadcrumbs
              data={[
                { name: "Event Groups", path: `/brand/${brand.id}/groups` },
                { name: `${group?.groupName}: ${event?.eventName}` },
              ]}
            ></Breadcrumbs>

            {/* 3D Experiences */}
            <div className="m-4">
              <div className="sm:flex w-full mb-4">
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white inline-block">
                  3D Experiences
                </h2>
                <div className="inline-block ml-4">
                  <EditExperienceButtonAndModal
                    experienceId={undefined}
                    group={group}
                    event={event}
                    setErrorData={setErrorData}
                    setErrorModalIsOpen={setErrorModalIsOpen}
                  />
                </div>
              </div>
              {brand && event && (
                <ExperiencesTable
                  brand={brand}
                  group={group}
                  event={event}
                  setErrorData={setErrorData}
                  setErrorModalIsOpen={setErrorModalIsOpen}
                />
              )}
            </div>
            {/* Store Integrations */}
            <div className="m-4 mt-10">
              <div className="sm:flex w-full mb-4">
                <div className="w-3/4">
                  <h2 className="text-2xl font-semibold text-gray-900 dark:text-white inline-block">
                    Store Integrations
                  </h2>
                  <div className="inline-block ml-4">
                    <SelectStoreButtonAndModal
                      buttonCopy={"Add Store"}
                      selection={(id) => addStoreToEvent(id)}
                      setErrorData={setErrorData}
                      setErrorModalIsOpen={setErrorModalIsOpen}
                    />
                  </div>
                </div>
              </div>
              {brand && event && <StoresTable brand={brand} stores={stores} />}
            </div>
            {/* Experience Settings */}
            <div className="m-4 mt-10">
              <div className="sm:flex w-full mb-4">
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white inline-block">
                  Event Settings
                </h2>
                <div className="inline-block ml-4">
                  <EditEventButtonAndModal
                    eventId={eventId}
                    group={group}
                    setErrorData={setErrorData}
                    setErrorModalIsOpen={setErrorModalIsOpen}
                  />
                </div>
              </div>
              {event && <EventSettings event={event} />}
            </div>
          </>
        )}

        <ErrorModal
          errorData={errorData}
          errorModalIsOpen={errorModalIsOpen}
          setErrorModalIsOpen={setErrorModalIsOpen}
        />
      </IonContent>
    </IonPage>
  );
};

const ExperiencesTable: FC<{
  brand: Brand;
  group: Group;
  event: Event | undefined;
  setErrorData: React.Dispatch<React.SetStateAction<Error | undefined>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ brand, group, event, setErrorData, setErrorModalIsOpen }) => {
  const [experiences, setExperiences] = useState<Experience[]>([]);
  const navigation = useIonRouter();

  useEffect(() => {
    const subscription = combineLatest([
      ExperienceService.getAllByEvent(
        String(auth.tenantId),
        String(brand.id),
        String(event?.id)
      ),
    ]).subscribe({
      next: ([experiences]) => {
        setExperiences(experiences);
      },
      error: (err) => {
        setErrorData(err);
        setErrorModalIsOpen(true);
        throw err;
      },
      complete: () => console.info("complete"),
    });

    return () => subscription.unsubscribe();
  }, []);

  function goToExperiencePage(experienceId: string) {
    navigation.push(
      `/brand/${brand.id}/groups/experience/${experienceId}`,
      "forward"
    );
  }

  return (
    <>
      <div className="overflow-x-auto">
        <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
          <Table.Head className="bg-gray-100 dark:bg-gray-700">
            <Table.HeadCell>Experience Name</Table.HeadCell>
            <Table.HeadCell>Products</Table.HeadCell>
            <Table.HeadCell>Polls/Trivia</Table.HeadCell>
            <Table.HeadCell>Videos</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
            {experiences.map((experience) => (
              <Table.Row
                className="hover:bg-gray-100 dark:hover:bg-gray-700"
                key={experience.id}
              >
                <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                  <div className="text-base font-semibold text-gray-900 dark:text-white">
                    {experience.experienceName}
                  </div>
                  <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {DataTransformationService.convertToLuxonFormat(
                      experience.experienceStartDate,
                      "LLLL d, y, ttt"
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  {/* {experience.productIds?.length || 0} */}
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  {/* {experience.questionIds?.length || 0} */}
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  {/* {experience.videoIds?.length || 0} */}
                </Table.Cell>
                <Table.Cell>
                  <div className="inline-block m-2">
                    <IonButton
                      size="small"
                      color="primary"
                      onClick={() => {
                        goToExperiencePage(experience.id);
                      }}
                      className="mr-4"
                    >
                      Manage
                      <HiChevronDoubleRight className="ml-1 h-3 w-3" />
                    </IonButton>
                  </div>
                  {/* <div className="inline-block m-2">
                  <EditExperienceButtonAndModal
                    buttonCopy="Edit"
                    experienceId={experience.id}
                    group={group}
                    event={event}
                    setErrorData={setErrorData}
                    setErrorModalIsOpen={setErrorModalIsOpen}
                  />
                </div> */}
                  <div className="inline-block m-2">
                    <DeleteExperienceButtonAndModal
                      buttonCopy="Delete"
                      experienceId={experience.id}
                      setErrorData={setErrorData}
                      setErrorModalIsOpen={setErrorModalIsOpen}
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      {experiences.length === 0 && (
        <div className="m-4">
          <p>
            Create your first 3D experience by clicking the "New Experience"
            button.
          </p>
        </div>
      )}
    </>
  );
};

const StoresTable: FC<{
  brand: Brand;
  stores: Store[];
}> = ({ brand, stores }) => {
  return (
    <>
      <div className="overflow-x-auto">
        <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
          <Table.Head className="bg-gray-100 dark:bg-gray-700">
            <Table.HeadCell>Store Name</Table.HeadCell>
            <Table.HeadCell>Currency</Table.HeadCell>
            <Table.HeadCell>Store URL</Table.HeadCell>
            <Table.HeadCell>Supports Shopping Cart</Table.HeadCell>
            <Table.HeadCell>Supports Shopping List</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
            {stores.map((store) => (
              <Table.Row
                className="hover:bg-gray-100 dark:hover:bg-gray-700"
                key={store.id}
              >
                <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                  <div className="text-base font-semibold text-gray-900 dark:text-white">
                    {store.storeName}
                  </div>
                  <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {store.storeType}
                  </div>
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  {store.storeCurrency}
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  <a href={store.storeUrl} target="_blank">
                    {store.storeUrl}
                  </a>
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  {store.supportsExperienceShoppingCart ? "Yes" : "No"}
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  {store.supportsExperienceShoppingList ? "Yes" : "No"}
                </Table.Cell>
                <Table.Cell>
                  <IonButton
                    size="small"
                    color="primary"
                    routerLink={`/brand/${brand.id}/stores/${store.id}`}
                    routerDirection="forward"
                  >
                    Manage
                    <HiChevronDoubleRight className="ml-1 h-3 w-3" />
                  </IonButton>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      {stores.length === 0 && (
        <div className="m-4">
          <p>Add stores to your event by clicking the "Edit Event" button.</p>
        </div>
      )}
    </>
  );
};

const EventSettings: FC<{
  event: Event;
}> = ({ event }) => {
  return (
    <>
      <h3 className="text-md font-bold">User Registration Required</h3>
      <div className="overflow-x-auto">
        <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
          <Table.Head className="bg-gray-100 dark:bg-gray-700">
            <Table.HeadCell>Registration Stage</Table.HeadCell>
            <Table.HeadCell>Required?</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
            <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                <div className="text-base font-semibold text-gray-900 dark:text-white">
                  User registration required before accessing experience.
                </div>
              </Table.Cell>
              <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                {event.requireRegistrationBeforeExperience ? "Yes" : "No"}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                <div className="text-base font-semibold text-gray-900 dark:text-white">
                  User registration required before answering question.
                </div>
              </Table.Cell>
              <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                {event.requireRegistrationBeforeAnsweringQuestion
                  ? "Yes"
                  : "No"}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      <h3 className="text-md font-bold mt-6">User Registration Fields</h3>
      <div className="overflow-x-auto">
        <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
          <Table.Head className="bg-gray-100 dark:bg-gray-700">
            <Table.HeadCell>Field Name</Table.HeadCell>
            <Table.HeadCell>Field Setting</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
            <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                <div className="text-base font-semibold text-gray-900 dark:text-white">
                  First Name
                </div>
              </Table.Cell>
              <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                {event.userRegistrationFieldRequirements_firstName}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                <div className="text-base font-semibold text-gray-900 dark:text-white">
                  Last Name
                </div>
              </Table.Cell>
              <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                {event.userRegistrationFieldRequirements_lastName}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                <div className="text-base font-semibold text-gray-900 dark:text-white">
                  Email Address
                </div>
              </Table.Cell>
              <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                {event.userRegistrationFieldRequirements_emailAddress}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                <div className="text-base font-semibold text-gray-900 dark:text-white">
                  Phone Number
                </div>
              </Table.Cell>
              <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                {event.userRegistrationFieldRequirements_phoneNumber}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                <div className="text-base font-semibold text-gray-900 dark:text-white">
                  Postcode
                </div>
              </Table.Cell>
              <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                {event.userRegistrationFieldRequirements_postcode}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                <div className="text-base font-semibold text-gray-900 dark:text-white">
                  Gender
                </div>
              </Table.Cell>
              <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                {event.userRegistrationFieldRequirements_gender}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                <div className="text-base font-semibold text-gray-900 dark:text-white">
                  Date of Birth
                </div>
              </Table.Cell>
              <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                {event.userRegistrationFieldRequirements_dateOfBirth}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                <div className="text-base font-semibold text-gray-900 dark:text-white">
                  Opt-in Marketing
                </div>
              </Table.Cell>
              <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                {event.userRegistrationFieldRequirements_optInMarketing}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </>
  );
};

export default EventPage;
