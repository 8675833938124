/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Label, Spinner, TextInput } from "flowbite-react";
import { useEffect, type FC, useState } from "react";
import { useAuth } from "../../firebase/auth";
import AuthService from "../../services/auth.service";
import ErrorAlert from "../../components/ErrorAlert";
import { IonButton, IonPage, useIonRouter } from "@ionic/react";

const SignUpPage: FC = function () {
  const nav = useIonRouter();
  const { authUser } = useAuth();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [attemptingLogin, setAttemptingLogin] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (authUser) {
      nav.push("/tenant", "root", "replace");
    }
  }, [authUser]);

  const register = async () => {
    try {
      setErrorData(null);
      setAttemptingLogin(true);

      if (password !== confirmPassword)
        throw new Error("Passwords do not match.");

      if (password.length < 6)
        throw new Error(
          "Password is too short. Please use at least 6 characters."
        );

      await AuthService.emailRegistration(email, password, firstName, lastName);
      return;
    } catch (err: any) {
      setAttemptingLogin(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  };

  return (
    <IonPage>
      <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
        <a href="/" className="my-6 flex items-center gap-x-1 lg:my-0">
          <img
            alt="Drawbridge&trade; Dashboard Manager"
            src="/drawbridge-assets/drawbridge-dashboard-manager-logo.png"
            className="max-h-10"
          />
        </a>
        <Card
          horizontal
          className="w-full md:max-w-[1024px] md:[&>*]:w-full md:[&>*]:p-16 [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 lg:[&>img]:block"
        >
          <h1 className="mb-3 text-2xl font-bold dark:text-white md:text-3xl">
            Create an Account
          </h1>
          <form>
            <div className="mb-4 flex flex-col gap-y-3">
              <Label htmlFor="email">Your First Name</Label>
              <TextInput
                id="firstName"
                name="firstName"
                type="firstName"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="mb-4 flex flex-col gap-y-3">
              <Label htmlFor="email">Your Last Name</Label>
              <TextInput
                id="lastName"
                name="lastName"
                type="lastName"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="mb-4 flex flex-col gap-y-3">
              <Label htmlFor="email">Your email</Label>
              <TextInput
                id="email"
                name="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-6 flex flex-col gap-y-3">
              <Label htmlFor="password">Your password</Label>
              <TextInput
                id="password"
                name="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="mb-6 flex flex-col gap-y-3">
              <Label htmlFor="confirmPassword">Confirm password</Label>
              <TextInput
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="mb-7">
              <IonButton
                onClick={register}
                className="w-full lg:w-auto"
                disabled={attemptingLogin}
              >
                Create your account{" "}
                {attemptingLogin && (
                  <span className="ml-2">
                    <Spinner
                      color={"success"}
                      size="sm"
                      aria-label="Creating your account."
                    />
                  </span>
                )}
              </IonButton>
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">
              Already have an account?&nbsp;
              <a href="/" className="text-primary-600 dark:text-primary-200">
                Login here
              </a>
            </p>
          </form>
        </Card>
      </div>
      <ErrorAlert
        errorData={errorData}
        isOpen={errorModalIsOpen}
        setIsOpen={setErrorModalIsOpen}
      />
    </IonPage>
  );
};

export default SignUpPage;
