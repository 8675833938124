/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, type FC, useState } from "react";
import ErrorModal from "../../components/modals/error-modal";

import { useBrandContext } from "../../context/brand.context";
import { Subscription, combineLatest } from "rxjs";
import { useParams } from "react-router-dom";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import SpinnerCentered from "../../components/SpinnerCentered";
import Breadcrumbs from "../../components/Breadcrumbs";
import ProductService from "../../services/product.service";
import { Product } from "../../models/product";
import EditProductButtonAndModal from "../../components/modals/edit-product-button-and-modal";

const ProductPage: FC = function () {
  const brand = useBrandContext();
  const { productId } = useParams<{ productId: string }>();

  // Error Message States
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [product, setProduct] = useState<Product>();

  useEffect(() => {
    const subscription: Subscription = combineLatest([
      ProductService.getOne(productId),
    ]).subscribe({
      next: ([set]) => {
        setProduct(set);
        setIsLoading(false);
      },
      error: (err) => {
        setIsLoading(false);
        setErrorData(err);
        setErrorModalIsOpen(true);
        throw err;
      },
      complete: () => console.info("complete"),
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>{product?.productName}</IonTitle>
          <IonButtons slot="end">
            {/* <EditProductButtonAndModal
              buttonCopy={"Edit Product"}
              productId={productId}
              setErrorData={setErrorData}
              setErrorModalIsOpen={setErrorModalIsOpen}
            /> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{product?.productName}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {isLoading ? (
          <SpinnerCentered />
        ) : (
          <>
            <Breadcrumbs
              data={[
                {
                  name: "Stores & Products",
                  path: `/brand/${brand.id}/stores`,
                },
                { name: product?.productName },
              ]}
            ></Breadcrumbs>

            <p className="ion-padding">Product ID: {product.id}</p>
            <p className="ion-padding">Coming soon.</p>
          </>
        )}

        <ErrorModal
          errorData={errorData}
          errorModalIsOpen={errorModalIsOpen}
          setErrorModalIsOpen={setErrorModalIsOpen}
        />
      </IonContent>
    </IonPage>
  );
};

export default ProductPage;
