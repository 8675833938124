import { FC, useState } from "react";
import { Button, Modal, Spinner } from "flowbite-react";
import { HiOutlineExclamationCircle, HiTrash } from "react-icons/hi";
import QuestionService from "../../services/question.service";
import { IonButton } from "@ionic/react";

const DeleteQuestionButtonAndModal: FC<{
  buttonCopy?: string | undefined;
  questionId: string | undefined;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ buttonCopy, questionId, setErrorData, setErrorModalIsOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  async function deleteQuestion(questionId: string) {
    try {
      setIsDeleting(true);

      await QuestionService.deleteOne(questionId);

      setIsDeleting(false);
      setIsOpen(false);
    } catch (err) {
      setIsDeleting(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      <IonButton size="small" color="danger" onClick={() => setIsOpen(true)}>
        <HiTrash className="mr-1 h-3 w-3" />
        {buttonCopy ? buttonCopy : "Delete Question"}
      </IonButton>

      <Modal onClose={() => setIsOpen(false)} show={isOpen} size="md">
        <Modal.Header className="px-3 pb-0 pt-3">
          <span className="sr-only">Delete Question</span>
        </Modal.Header>
        <Modal.Body className="px-6 pb-6 pt-0" style={{ maxHeight: "60vh" }}>
          <div className="flex flex-col items-center gap-y-6 text-center">
            <HiOutlineExclamationCircle className="text-7xl text-red-600" />
            <p className="text-lg text-gray-500 dark:text-gray-300">
              Are you sure you want to delete this question?
            </p>
            <div className="flex items-center gap-x-3">
              <IonButton
                color="danger"
                onClick={() => deleteQuestion(questionId)}
              >
                Yes, I'm sure
                {isDeleting && (
                  <span className="ml-2">
                    <Spinner
                      color={"failure"}
                      size="sm"
                      aria-label="Deleting..."
                    />
                  </span>
                )}
              </IonButton>
              <IonButton color="gray" onClick={() => setIsOpen(false)}>
                No, cancel
              </IonButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteQuestionButtonAndModal;
