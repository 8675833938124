import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  useIonRouter,
} from "@ionic/react";

import { Redirect, Route, useLocation, useParams } from "react-router";
import {
  cartOutline,
  filmOutline,
  help,
  helpOutline,
  imageOutline,
  peopleCircleOutline,
  personCircleOutline,
  phonePortraitOutline,
  pieChartOutline,
  returnDownBackOutline,
} from "ionicons/icons";
import { BrandContext } from "../context/brand.context";
import BrandService from "../services/brand.service";
import { useEffect, useState } from "react";
import { useAuth } from "../firebase/auth";
import { Brand } from "../models/brand";
import GroupsPage from "../pages/brand/Groups";
import DashboardPage from "../pages/brand/Dashboard";
import {
  UserAccountTenantPermissionsContext,
  useUserAccountTenantPermissionsContext,
} from "../context/userAccountTenantPermissions.context";
import {
  UserAccountBrandPermissionsContext,
  useUserAccountBrandPermissionsContext,
} from "../context/userAccountBrandPermissions.context";
import UserAccountTenantPermissionService from "../services/user-account-tenant-permission.service";
import UserAccountBrandPermissionService from "../services/user-account-brand-permission.service";
import { combineLatest } from "rxjs";
import SpinnerCentered from "../components/SpinnerCentered";
import { UserAccountTenantPermission } from "../models/user-account-tenant-permission";
import { UserAccountBrandPermission } from "../models/user-account-brand-permission";
import EventPage from "../pages/brand/Event";
import ExperiencePage from "../pages/brand/Experience";
import SetsPage from "../pages/brand/Sets";
import QuestionsPage from "../pages/brand/Questions";
import QuestionPage from "../pages/brand/Question";
import SetPage from "../pages/brand/Set";
import VideosPage from "../pages/brand/Videos";
import BrandUsersPage from "../pages/brand/BrandUsers";
import MyAccountPage from "../pages/brand/MyAccount";
import StoresPage from "../pages/brand/Stores";
import ProductPage from "../pages/brand/Product";
import EditBrandModal from "../components/modals/edit-brand-button-and-modal";
import ErrorAlert from "../components/ErrorAlert";
import TenantService from "../services/tenant.service";
import { Tenant } from "../models/tenant";
import { TenantContext } from "../context/tenant.context";

interface MenuItem {
  url: string;
  icon: string;
  title: string;
  subTitle?: string;
}

const BrandMenu: React.FC = () => {
  const location = useLocation();
  const navigation = useIonRouter();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Error Message Handling
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);

  const params: any = useParams();

  const [brand, setBrand] = useState<Brand | undefined>(undefined);
  const [tenant, setTenant] = useState<Tenant | undefined>(undefined);

  const [userAccountTenantPermissions, setUserAccountTenantPermissions] =
    useState<UserAccountTenantPermission[] | undefined>(undefined);
  const [userAccountBrandPermissions, setUserAccountBrandPermissions] =
    useState<UserAccountBrandPermission[] | undefined>(undefined);

  const [brandMenuItems, setBrandMenuItems] = useState<MenuItem[]>([]);
  const [settingsMenuItems, setSettingsMenuItems] = useState<MenuItem[]>([]);

  const auth = useAuth();

  // Fetch Brand
  useEffect(() => {
    //redirect if user not logged in.
    if (!auth.isLoading && (!auth?.authUser || auth?.authUser?.isAnonymous)) {
      navigation.push("/", "root", "replace");
    } else if (!auth.isLoading && auth.authUser) {
      // fetch brand from API
      const subscription = combineLatest([
        TenantService.getOne(auth.authUser.tenantId),
        BrandService.getOne(params.brandId),
        UserAccountTenantPermissionService.getAllForUserAccount(
          auth.authUser.tenantId,
          auth.authUser.uid
        ),
        UserAccountBrandPermissionService.getAllForUserAccount(
          auth.authUser.tenantId,
          auth.authUser.uid
        ),
      ]).subscribe(
        ([
          tenant,
          brand,
          userAccountTenantPermissions,
          userAccountBrandPermissions,
        ]) => {
          if (!brand) {
            console.error("Brand not found: ", params.brandId);
            navigation.push("/404", "root");
          }
          setTenant(tenant);
          setBrand(brand);
          setUserAccountTenantPermissions(userAccountTenantPermissions);
          setUserAccountBrandPermissions(userAccountBrandPermissions);
          setIsLoading(false);
        }
      );
      return () => subscription.unsubscribe();
    } else {
      return;
    }
  }, [auth, params.brandId]);

  // Set Brand Menu Items
  useEffect(() => {
    if (brand && auth?.authUser) {
      const newBrandMenuItems: MenuItem[] = [
        {
          title: "Dashboard",
          url: "/brand/" + brand.id + "/dashboard",
          icon: pieChartOutline,
        },
        {
          title: "Events",
          url: "/brand/" + brand.id + "/groups",
          icon: phonePortraitOutline,
        },
        {
          title: "Stores & Products",
          url: "/brand/" + brand.id + "/stores",
          icon: cartOutline,
        },
        {
          title: "Videos",
          url: "/brand/" + brand.id + "/videos",
          icon: filmOutline,
        },
        {
          title: "Polls & Trivia",
          url: "/brand/" + brand.id + "/questions",
          icon: helpOutline,
        },
        {
          title: "3D Sets",
          url: "/brand/" + brand.id + "/sets",
          icon: imageOutline,
        },
      ];

      const newSettingsMenuItems: MenuItem[] = [
        {
          title: "User Management",
          url: "/brand/" + brand.id + "/users",
          icon: peopleCircleOutline,
        },
        {
          title: "My Account",
          subTitle: auth.authUser.email,
          url: "/brand/" + brand.id + "/my-account",
          icon: personCircleOutline,
        },
      ];

      setBrandMenuItems(newBrandMenuItems);
      setSettingsMenuItems(newSettingsMenuItems);
    }
  }, [brand, userAccountTenantPermissions, userAccountBrandPermissions]);

  return (
    <IonPage>
      {isLoading ? (
        <SpinnerCentered />
      ) : (
        <TenantContext.Provider value={tenant}>
          <UserAccountTenantPermissionsContext.Provider
            value={userAccountTenantPermissions}
          >
            <UserAccountBrandPermissionsContext.Provider
              value={userAccountTenantPermissions}
            >
              <BrandContext.Provider value={brand}>
                {brand && (
                  <IonSplitPane contentId="main">
                    <IonMenu contentId="main" type="overlay">
                      <IonContent>
                        <img
                          alt="Drawbridge&trade; Dashboard Manager"
                          src="/drawbridge-assets/drawbridge-dashboard-manager-logo.png"
                          className="ion-padding"
                        />
                        <IonList id="brand-menu">
                          <IonListHeader>
                            <IonLabel>
                              {brand.brandName}
                              {userAccountTenantPermissions?.length > 0 &&
                                userAccountTenantPermissions[0].admin && (
                                  <EditBrandModal
                                    brandId={brand.id}
                                    setErrorData={setErrorData}
                                    setErrorModalIsOpen={setErrorModalIsOpen}
                                    buttonCopy=""
                                  />
                                )}
                              <p>
                                {brand.hostname || tenant.hostnameForExperience}
                              </p>
                            </IonLabel>
                          </IonListHeader>
                          {brandMenuItems.map((menuItem, index) => {
                            return (
                              <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                  className={
                                    location.pathname.includes(menuItem.url)
                                      ? "selected"
                                      : ""
                                  }
                                  routerLink={menuItem.url}
                                  routerDirection="none"
                                  lines="inset"
                                  detail={false}
                                >
                                  <IonIcon
                                    aria-hidden="true"
                                    slot="start"
                                    icon={menuItem.icon}
                                  />
                                  <IonLabel>
                                    <h2>{menuItem.title}</h2>
                                    {menuItem.subTitle && (
                                      <p>{menuItem.subTitle}</p>
                                    )}
                                  </IonLabel>
                                </IonItem>
                              </IonMenuToggle>
                            );
                          })}
                        </IonList>
                        <IonList id="settings-menu">
                          <IonNote>Settings</IonNote>
                          {settingsMenuItems.map((menuItem, index) => {
                            return (
                              <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                  className={
                                    location.pathname.includes(menuItem.url)
                                      ? "selected"
                                      : ""
                                  }
                                  routerLink={menuItem.url}
                                  routerDirection="none"
                                  lines="inset"
                                  detail={false}
                                >
                                  <IonIcon
                                    aria-hidden="true"
                                    slot="start"
                                    icon={menuItem.icon}
                                  />
                                  <IonLabel>
                                    <h2>{menuItem.title}</h2>
                                    {menuItem.subTitle && (
                                      <p>{menuItem.subTitle}</p>
                                    )}
                                  </IonLabel>
                                </IonItem>
                              </IonMenuToggle>
                            );
                          })}
                        </IonList>
                      </IonContent>
                      <IonFooter>
                        {(userAccountBrandPermissions?.length > 1 ||
                          userAccountTenantPermissions?.length > 0) && (
                          <IonList>
                            <IonMenuToggle autoHide={false}>
                              <IonItem
                                routerLink={"/tenant/brands"}
                                routerDirection="none"
                                lines="none"
                                detail={false}
                              >
                                <IonIcon
                                  aria-hidden="true"
                                  slot="start"
                                  icon={returnDownBackOutline}
                                />
                                <IonLabel>
                                  <h2>Back to All Brands</h2>
                                </IonLabel>
                              </IonItem>
                            </IonMenuToggle>
                          </IonList>
                        )}
                        <div className="ion-text-center">
                          <IonButton
                            size="small"
                            fill="clear"
                            onClick={() =>
                              navigation.push("/logout", "root", "replace")
                            }
                          >
                            Logout
                          </IonButton>
                          <p className="text-xs">
                            Copyright 2024 Drawbridge Labs
                          </p>
                        </div>
                      </IonFooter>
                    </IonMenu>

                    <IonRouterOutlet id="main">
                      {/* Brand Navigation */}
                      <Route
                        exact
                        path="/brand/:brandId/dashboard"
                        component={DashboardPage}
                      />
                      <Route
                        exact
                        path="/brand/:brandId/groups"
                        component={GroupsPage}
                      />
                      <Route
                        exact
                        path="/brand/:brandId/groups/event/:eventId"
                        component={EventPage}
                      />
                      <Route
                        exact
                        path="/brand/:brandId/groups/experience/:experienceId"
                        component={ExperiencePage}
                      />
                      <Route
                        exact
                        path="/brand/:brandId/stores"
                        component={StoresPage}
                      />
                      <Route
                        exact
                        path="/brand/:brandId/stores/product/:productId"
                        component={ProductPage}
                      />
                      <Route
                        exact
                        path="/brand/:brandId/videos"
                        component={VideosPage}
                      />
                      <Route
                        exact
                        path="/brand/:brandId/questions"
                        component={QuestionsPage}
                      />
                      <Route
                        exact
                        path="/brand/:brandId/questions/:questionId"
                        component={QuestionPage}
                      />
                      <Route
                        exact
                        path="/brand/:brandId/sets"
                        component={SetsPage}
                      />
                      <Route
                        exact
                        path="/brand/:brandId/sets/:setId"
                        component={SetPage}
                      />

                      {/* Settings Navigation */}
                      <Route
                        exact
                        path="/brand/:brandId/users"
                        component={BrandUsersPage}
                      />
                      <Route
                        exact
                        path="/brand/:brandId/my-account"
                        component={MyAccountPage}
                      />

                      {/* Redirect Default */}
                      <Redirect
                        exact
                        path="/brand/:brandId"
                        to="/brand/:brandId/dashboard"
                      />
                    </IonRouterOutlet>
                  </IonSplitPane>
                )}
              </BrandContext.Provider>
            </UserAccountBrandPermissionsContext.Provider>
          </UserAccountTenantPermissionsContext.Provider>
        </TenantContext.Provider>
      )}
      <ErrorAlert
        errorData={errorData}
        isOpen={errorModalIsOpen}
        setIsOpen={setErrorModalIsOpen}
      />
    </IonPage>
  );
};

export default BrandMenu;
