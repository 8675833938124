import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { useForm } from "react-hook-form";
import { firstValueFrom } from "rxjs";
import { Spinner } from "flowbite-react";
import { auth } from "../../firebase/firebase";
import { HiPencilAlt, HiPlus } from "react-icons/hi";
import VideoService from "../../services/video.service";
import { Video } from "../../models/video";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

const EditVideoButtonAndModal: FC<{
  buttonCopy?: string | undefined;
  videoId: string | undefined;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ buttonCopy, videoId, setErrorData, setErrorModalIsOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      id: null,
      tenantId: null,
      brandId: null,
      videoName: null,
      videoDescription: null,
      videoAssetUrl: null,
      videoStreamUrl: null,
      videoThumbnailUrl: null,
    },
  });
  const brand = useBrandContext();

  useEffect(() => {
    if (isOpen) {
      if (videoId) {
        firstValueFrom(VideoService.getOne(videoId))
          .then((video) => {
            updateFormValues(video);
          })
          .catch((err) => {
            setErrorData(err);
            setErrorModalIsOpen(true);
            throw err;
          });
      } else {
        const newVideo: Partial<Video> = {
          id: VideoService.generateId(),
          tenantId: auth.tenantId,
          brandId: brand.id,
        };
        updateFormValues(newVideo);
      }
    }
  }, [isOpen, videoId]);

  function updateFormValues(video: Partial<Video>) {
    setValue("id", video.id);
    setValue("tenantId", video.tenantId);
    setValue("brandId", video.brandId);
    setValue("videoName", video.videoName || null);
    setValue("videoDescription", video.videoDescription || null);
    setValue("videoAssetUrl", video.videoAssetUrl || null);
    setValue("videoStreamUrl", video.videoStreamUrl || null);
    setValue("videoThumbnailUrl", video.videoThumbnailUrl || null);
  }

  async function onSubmit(formData: any) {
    try {
      setIsSaving(true);

      await VideoService.saveOne(formData, videoId ? false : true);

      setIsSaving(false);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      {videoId ? (
        <IonButton
          size="small"
          fill="solid"
          color="warning"
          onClick={() => setIsOpen(true)}
        >
          <HiPencilAlt className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "Edit Video"}
        </IonButton>
      ) : (
        <IonButton
          size="small"
          fill="solid"
          color="success"
          onClick={() => setIsOpen(true)}
        >
          <HiPlus className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "New Video"}
        </IonButton>
      )}

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {videoId ? (
                <strong>Edit Video</strong>
              ) : (
                <strong>New Video</strong>
              )}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)} color="primary">
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList>
              <IonItem>
                <IonInput {...register("videoName")} labelPlacement="floating">
                  <div slot="label">
                    Video name <IonText color="danger">(Required)</IonText>
                  </div>
                </IonInput>
              </IonItem>
              <IonItem>
                <IonTextarea
                  rows={4}
                  {...register("videoDescription")}
                  labelPlacement="floating"
                >
                  <div slot="label">Video description</div>
                </IonTextarea>
              </IonItem>
              <IonItem>
                <IonInput
                  {...register("videoStreamUrl")}
                  labelPlacement="floating"
                >
                  <div slot="label">
                    Video stream url (Youtube, Vimeo, etc){" "}
                    <IonText color="danger">(Required)</IonText>
                  </div>
                </IonInput>
              </IonItem>
            </IonList>

            <div className="ion-padding">
              <IonButton type="submit" color="primary" disabled={isSaving}>
                Save
                {isSaving && (
                  <span className="ml-2">
                    <Spinner
                      color={"success"}
                      size="sm"
                      aria-label="Saving..."
                    />
                  </span>
                )}
              </IonButton>
              <IonButton
                color="danger"
                fill="clear"
                onClick={() => setIsOpen(false)}
                disabled={isSaving}
              >
                Cancel
              </IonButton>
            </div>
          </form>
        </IonContent>
      </IonModal>
    </>
  );
};

export default EditVideoButtonAndModal;
