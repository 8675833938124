import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { useFieldArray, useForm } from "react-hook-form";
import { firstValueFrom } from "rxjs";
import {
  Button,
  Label,
  Modal,
  Spinner,
  TextInput,
  Textarea,
} from "flowbite-react";
import { auth } from "../../firebase/firebase";
import { HiPencilAlt, HiPlus, HiTrash } from "react-icons/hi";
import QuestionService from "../../services/question.service";
import { Question } from "../../models/question";
import FileUploadInput from "../file-upload-input";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
  IonTextarea,
  IonLabel,
  IonItemDivider,
  IonCard,
} from "@ionic/react";
import { addOutline, closeOutline } from "ionicons/icons";

const EditQuestionButtonAndModal: FC<{
  buttonCopy?: string | undefined;
  questionId: string | undefined;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ buttonCopy, questionId, setErrorData, setErrorModalIsOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { register, handleSubmit, setValue, control, reset, watch } = useForm({
    defaultValues: {
      id: null,
      tenantId: null,
      brandId: null,
      questionText: null,
      questionImageUrl: null,
      answerOptions: [],
    },
  });

  const answerOptionsFieldArray = useFieldArray({
    control,
    name: "answerOptions",
  });

  const brand = useBrandContext();

  useEffect(() => {
    if (isOpen) {
      for (let i = 0; i < answerOptionsFieldArray.fields.length; i++) {}
      if (questionId) {
        firstValueFrom(QuestionService.getOne(questionId))
          .then((question) => {
            updateFormValues(question);
          })
          .catch((err) => {
            setErrorData(err);
            setErrorModalIsOpen(true);
            throw err;
          });
      } else {
        const newQuestion: Partial<Question> = {
          id: QuestionService.generateId(),
          tenantId: auth.tenantId,
          brandId: brand.id,
        };
        updateFormValues(newQuestion);
      }
    }

    return () => reset(); //reset form
  }, [isOpen]);

  function updateFormValues(question: Partial<Question>) {
    setValue("id", question.id);
    setValue("tenantId", question.tenantId);
    setValue("brandId", question.brandId);
    setValue("questionText", question.questionText || null);
    setValue("questionImageUrl", question.questionImageUrl || null);
    setValue("answerOptions", question.answerOptions || []);
  }

  async function onSubmit(formData: any) {
    try {
      setIsSaving(true);

      await QuestionService.saveOne(formData, questionId ? false : true);

      setIsSaving(false);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  async function uploadImage(files: FileList | null) {
    try {
      if (!files || files.length == 0) {
        setValue("questionImageUrl", null);
        return;
      }

      setIsSaving(true);
      const file = files[0];
      const fileUrl = await QuestionService.uploadQuestionImage(
        brand.id,
        watch("id"),
        file
      );
      setValue("questionImageUrl", fileUrl);
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      {questionId ? (
        <IonButton
          size="small"
          fill="solid"
          color="warning"
          onClick={() => setIsOpen(true)}
        >
          <HiPencilAlt className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "Edit Question"}
        </IonButton>
      ) : (
        <IonButton
          size="small"
          fill="solid"
          color="success"
          onClick={() => setIsOpen(true)}
        >
          <HiPlus className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "New Question"}
        </IonButton>
      )}

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {questionId ? (
                <strong>Edit Question</strong>
              ) : (
                <strong>New Question</strong>
              )}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)} color="primary">
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList>
              <IonItem>
                <IonTextarea
                  rows={4}
                  {...register("questionText")}
                  labelPlacement="floating"
                >
                  <div slot="label">
                    Question Text <IonText color="danger">(Required)</IonText>
                  </div>
                </IonTextarea>
              </IonItem>

              <IonLabel className="ion-padding">Question Image</IonLabel>
              <IonCard>
                {watch("questionImageUrl") && (
                  <img
                    src={watch("questionImageUrl")}
                    alt=""
                    className="mb-4"
                  />
                )}
                <FileUploadInput upload={uploadImage} isUploading={isSaving} />
                {watch("questionImageUrl") && (
                  <IonButton
                    size="small"
                    className="inline-block"
                    color="danger"
                    onClick={() => {
                      uploadImage(null);
                    }}
                  >
                    <HiTrash className="mr-1 h-3 w-3" />
                    Remove
                  </IonButton>
                )}
              </IonCard>

              <IonItemDivider>Answer Options</IonItemDivider>
              {answerOptionsFieldArray.fields.map((item, index) => (
                <IonItem key={item.id}>
                  <IonInput
                    {...register(`answerOptions.${index}.answerOptionText`)}
                    defaultValue={item.answerOptionText}
                    className="inline-block w-3/4"
                  />
                  <IonButton
                    slot="end"
                    size="small"
                    color="danger"
                    onClick={() => answerOptionsFieldArray.remove(index)}
                    className="inline-block ml-4"
                  >
                    Delete
                  </IonButton>
                </IonItem>
              ))}
              <div className="ion-text-center">
                <IonButton
                  size="small"
                  color="success"
                  onClick={() =>
                    answerOptionsFieldArray.append({
                      id: QuestionService.generateId(),
                      answerOptionText: "",
                      answerOptionImageUrl: "",
                    })
                  }
                >
                  <IonIcon slot="start" icon={addOutline} />
                  Add Answer Option
                </IonButton>
              </div>
            </IonList>

            <div className="ion-padding">
              <IonButton type="submit" color="primary" disabled={isSaving}>
                Save
                {isSaving && (
                  <span className="ml-2">
                    <Spinner
                      color={"success"}
                      size="sm"
                      aria-label="Saving..."
                    />
                  </span>
                )}
              </IonButton>
              <IonButton
                color="danger"
                fill="clear"
                onClick={() => setIsOpen(false)}
                disabled={isSaving}
              >
                Cancel
              </IonButton>
            </div>
          </form>
        </IonContent>
      </IonModal>
    </>
  );
};

export default EditQuestionButtonAndModal;
