import {
  DocumentData,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db, functionsUrl } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { Product } from "../models/product";
import axios from "axios";

function generateId() {
  return doc(collection(db, "products")).id;
}

function getOne(id: string): Observable<Product> {
  if (!id) return of(undefined);
  const docRef = doc(db, `products/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAllByBrand(
  tenantId: string,
  brandId: string
): Observable<Product[]> {
  const collectionRef = collection(db, "products");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort((a, b) => a.productName.localeCompare(b.productName));
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

function getAllByStoreIds(
  tenantId: string,
  brandId: string,
  storeIds: string[]
): Observable<Product[]> {
  //Only query with valid storeIds array
  if (!storeIds || !Array.isArray(storeIds) || storeIds.length === 0)
    return of([]);

  //Limit query due to firebase limitation
  if (storeIds.length > 10) throw new Error("Currently limited to ten stores.");

  const collectionRef = collection(db, "products");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("storeId", "in", storeIds),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) =>
          (a.createdDate && a.createdDate instanceof Timestamp
            ? a.createdDate.seconds
            : 99999999999) -
          (b.createdDate && b.createdDate instanceof Timestamp
            ? b.createdDate.seconds
            : 99999999999)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

function getAllByIds(
  tenantId: string,
  brandId: string,
  productIds: string[]
): Observable<Product[]> {
  //Only query with valid productIds array
  if (!productIds || !Array.isArray(productIds) || productIds.length === 0)
    return of([]);

  //Limit query due to firebase limitation
  if (productIds.length > 10)
    throw new Error("Currently limited to ten products.");

  const collectionRef = collection(db, "products");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("id", "in", productIds),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) =>
          (a.createdDate && a.createdDate instanceof Timestamp
            ? a.createdDate.seconds
            : 99999999999) -
          (b.createdDate && b.createdDate instanceof Timestamp
            ? b.createdDate.seconds
            : 99999999999)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

async function saveOne(
  product: Partial<Product>,
  isNew: boolean
): Promise<any> {
  try {
    if (isNew) {
      product.createdDate = serverTimestamp();
      product.createdByUserAccountId = auth.currentUser.uid;
    } else {
      product.updatedDate = serverTimestamp();
      product.updatedByUserAccountId = auth.currentUser.uid;
    }

    if (product.deleted == undefined) product.deleted = false;

    product =
      DataTransformationService.convertDatesToFirestoreTimestamp(product);

    let docRef = doc(db, `products/${product.id}`);
    await setDoc(docRef, product, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(productId: string): Promise<any> {
  try {
    let docRef = doc(db, `products/${productId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

// Uplaods file and returns the file url
async function uploadProductFile(
  brandId: string,
  productId: string,
  file: File
): Promise<string> {
  try {
    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //get signed url from firebase function
    const apiResponse = await client.post(
      `brand/${brandId}/products/r2-signed-url`,
      {
        productId: productId,
        fileName: file.name,
      }
    );

    const signedUrlAndFileUrl: { signedUrl: string; fileUrl: string } =
      apiResponse?.data;
    if (!signedUrlAndFileUrl)
      throw new Error("Error getting signed url during upload process.");

    //upload image to r2
    await axios.put(signedUrlAndFileUrl.signedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    return signedUrlAndFileUrl.fileUrl;
  } catch (err) {
    throw err;
  }
}

const ProductService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllByBrand,
  getAllByStoreIds,
  getAllByIds,
  uploadProductFile,
};

export default ProductService;
