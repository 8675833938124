import { IonAlert } from "@ionic/react";
import { FC } from "react";

const ErrorAlert: FC<{
  errorData: Error | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ errorData, isOpen, setIsOpen }) => {
  return (
    <>
      <IonAlert
        isOpen={isOpen}
        header="Error"
        message={errorData?.message}
        buttons={["Ok"]}
        onDidDismiss={() => setIsOpen(false)}
      ></IonAlert>
    </>
  );
};

export default ErrorAlert;
