import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { useForm } from "react-hook-form";
import { firstValueFrom } from "rxjs";
import GroupService from "../../services/group.service";
import { Spinner } from "flowbite-react";
import { Group } from "../../models/group";
import { auth } from "../../firebase/firebase";
import { HiPencilAlt, HiPlus } from "react-icons/hi";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

const EditGroupButtonAndModal: FC<{
  buttonCopy?: string | undefined;
  groupId: string | undefined;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ buttonCopy, groupId, setErrorData, setErrorModalIsOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { groupName: "" },
  });
  const brand = useBrandContext();

  useEffect(() => {
    if (isOpen) {
      if (groupId) {
        firstValueFrom(GroupService.getOne(groupId))
          .then((group) => {
            setValue("groupName", group.groupName);
          })
          .catch((err) => {
            setErrorData(err);
            setErrorModalIsOpen(true);
            throw err;
          });
      } else {
        setValue("groupName", "");
      }
    }
  }, [isOpen, groupId]);

  async function onSubmit(formData: any) {
    try {
      setIsSaving(true);

      const group: Group = {
        tenantId: auth.tenantId,
        brandId: brand.id,
        groupName: formData.groupName,
      };
      let isNew: boolean = false;

      if (groupId) {
        group.id = groupId;
      } else {
        group.id = GroupService.generateId();
        isNew = true;
      }

      await GroupService.saveOne(group, isNew);

      setIsSaving(false);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      {groupId ? (
        <IonButton
          size="small"
          fill="solid"
          color="warning"
          onClick={() => setIsOpen(true)}
        >
          <HiPencilAlt className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "Edit Group"}
        </IonButton>
      ) : (
        <IonButton
          size="small"
          fill="solid"
          color="success"
          onClick={() => setIsOpen(true)}
        >
          <HiPlus className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "New Group"}
        </IonButton>
      )}

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {groupId ? (
                <strong>Edit Group</strong>
              ) : (
                <strong>New Group</strong>
              )}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)} color="primary">
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList>
              <IonItem>
                <IonInput {...register("groupName")} labelPlacement="floating">
                  <div slot="label">
                    Group name <IonText color="danger">(Required)</IonText>
                  </div>
                </IonInput>
              </IonItem>
            </IonList>
            <div className="ion-padding">
              <IonButton type="submit" color="primary" disabled={isSaving}>
                Save
                {isSaving && (
                  <span className="ml-2">
                    <Spinner
                      color={"success"}
                      size="sm"
                      aria-label="Saving..."
                    />
                  </span>
                )}
              </IonButton>
              <IonButton
                color="danger"
                fill="clear"
                onClick={() => setIsOpen(false)}
                disabled={isSaving}
              >
                Cancel
              </IonButton>
            </div>
          </form>
        </IonContent>
      </IonModal>
    </>
  );
};

export default EditGroupButtonAndModal;
