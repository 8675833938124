/* eslint-disable jsx-a11y/anchor-is-valid */
import { Badge, Spinner, Table } from "flowbite-react";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { HiOutlinePencilAlt, HiPlus, HiX } from "react-icons/hi";
import { combineLatest, first, firstValueFrom } from "rxjs";
import { auth } from "../../firebase/firebase";
import { UserAccountTenantPermission } from "../../models/user-account-tenant-permission";
import UserAccountTenantPermissionService from "../../services/user-account-tenant-permission.service";
import SpinnerCentered from "../../components/SpinnerCentered";
import ErrorAlert from "../../components/ErrorAlert";
import { useForm } from "react-hook-form";
import { UserAccount } from "../../models/user-account";
import UserAccountService from "../../services/user-account.service";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonMenuButton,
  IonModal,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addOutline, closeOutline } from "ionicons/icons";

const TenantUsersPage: FC = function () {
  const pageTitle = `System Admin Users`;

  // Error Message States
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorAlertIsOpen, setErrorAlertIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [userAccountTenantPermissions, setUserAccountTenantPermissions] =
    useState<UserAccountTenantPermission[]>([]);

  useEffect(() => {
    const subscription = combineLatest([
      UserAccountTenantPermissionService.getAllForTenant(auth.tenantId),
    ]).subscribe({
      next: ([userAccountTenantPermissions]) => {
        setUserAccountTenantPermissions(userAccountTenantPermissions);
        setIsLoading(false);
      },
      error: (err) => {
        setIsLoading(false);
        setErrorData(err);
        setErrorAlertIsOpen(true);
        throw err;
      },
      complete: () => console.info("complete"),
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
          <IonButtons slot="end">
            <AddUserModal
              setErrorData={setErrorData}
              setErrorModalIsOpen={setErrorAlertIsOpen}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {isLoading ? (
          <SpinnerCentered />
        ) : (
          <>
            <div className="overflow-x-auto">
              <Table className="w-full divide-y divide-gray-200 dark:divide-gray-600">
                <Table.Head className="bg-gray-100 dark:bg-gray-700">
                  {/* <Table.HeadCell>
                        <Label htmlFor="select-all" className="sr-only">
                          Select all
                        </Label>
                        <Checkbox id="select-all" name="select-all" />
                      </Table.HeadCell> */}
                  <Table.HeadCell>Name</Table.HeadCell>
                  <Table.HeadCell>Tenant Permissions</Table.HeadCell>
                  <Table.HeadCell>Actions</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
                  {userAccountTenantPermissions.map(
                    (userAccountTenantPermission) => (
                      <Table.Row
                        key={userAccountTenantPermission.id}
                        className="hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        {/* <Table.Cell className="w-4 p-4">
                              <div className="flex items-center">
                                <Checkbox
                                  aria-describedby="checkbox-1"
                                  id="checkbox-1"
                                />
                                <label htmlFor="checkbox-1" className="sr-only">
                                  checkbox
                                </label>
                              </div>
                            </Table.Cell> */}
                        <Table.Cell className="mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0">
                          {/* <img
                            className="h-10 w-10 rounded-full"
                            src="../../images/users/neil-sims.png"
                            alt="Neil Sims avatar"
                          /> */}
                          <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                            <div className="text-base font-semibold text-gray-900 dark:text-white">
                              {userAccountTenantPermission.firstName}{" "}
                              {userAccountTenantPermission.lastName}
                            </div>
                            <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                              {userAccountTenantPermission.emailAddress}
                            </div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className=" p-4 text-base font-medium text-gray-900 dark:text-white">
                          {userAccountTenantPermission.admin && (
                            <Badge className="inline-block" color="failure">
                              Admin
                            </Badge>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <div className="flex items-center gap-x-3 whitespace-nowrap">
                            {/* <EditUserPermissionsModal
                              userAccountTenantPermission={
                                userAccountTenantPermission
                              }
                              setErrorData={setErrorData}
                              setErrorModalIsOpen={setErrorAlertIsOpen}
                            /> */}
                            <RemoveUserPermissionModal
                              userAccountTenantPermission={
                                userAccountTenantPermission
                              }
                              setErrorData={setErrorData}
                              setErrorModalIsOpen={setErrorAlertIsOpen}
                            />
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>
            </div>
          </>
        )}
        <ErrorAlert
          errorData={errorData}
          isOpen={errorAlertIsOpen}
          setIsOpen={setErrorAlertIsOpen}
        />
      </IonContent>
    </IonPage>
  );
};

const AddUserModal: FC<{
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setErrorData, setErrorModalIsOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      emailAddress: "",
    },
  });

  useEffect(() => {
    updateFormValues();
  }, [isOpen]);

  function updateFormValues() {
    setValue("emailAddress", "");
  }

  async function onSubmit(formData: any) {
    try {
      setIsSaving(true);

      const emailAddress: string = formData.emailAddress.toLowerCase();

      const userAccounts: UserAccount[] = await firstValueFrom(
        UserAccountService.getAllByEmailAddress(auth.tenantId, emailAddress)
      );

      if (userAccounts.length === 0)
        throw new Error(`No user found with ${emailAddress}`);

      // take the first user account found with the email address (shouldn't be more than one)
      const userAccount: UserAccount = userAccounts[0];

      //check to see if there is a permissions account for this user
      const existingUserAccountTenantPermissions: UserAccountTenantPermission[] =
        await firstValueFrom(
          UserAccountTenantPermissionService.getAllForUserAccountIncludingDeleted(
            auth.tenantId,
            userAccount.id
          )
        );

      const existingUserAccountTenantPermission: UserAccountTenantPermission =
        existingUserAccountTenantPermissions?.length > 0
          ? existingUserAccountTenantPermissions[0]
          : null;
      const isNew: boolean = existingUserAccountTenantPermission ? false : true;

      // create a new user account tenant permission object. reuse the existing ID if it exists for user
      const userAccountTenantPermission: UserAccountTenantPermission = {
        id: existingUserAccountTenantPermission
          ? existingUserAccountTenantPermission.id
          : UserAccountTenantPermissionService.generateId(
              userAccount.id,
              auth.tenantId
            ),
        tenantId: auth.tenantId,
        userAccountId: userAccount.id,
        firstName: userAccount.firstName,
        lastName: userAccount.lastName,
        emailAddress: userAccount.emailAddress,
        admin: true,
      };

      await UserAccountTenantPermissionService.saveOne(
        userAccountTenantPermission,
        isNew
      );

      setIsSaving(false);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      <IonButton color="primary" onClick={() => setIsOpen(true)}>
        <div className="flex items-center gap-x-3">
          <HiPlus className="text-xl" />
          Add user
        </div>
      </IonButton>

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              <strong>Add new user</strong>
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)} color="primary">
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList>
              <IonItem>
                <IonInput
                  {...register("emailAddress")}
                  labelPlacement="floating"
                  type="email"
                >
                  <div slot="label">
                    Email address <IonText color="danger">(Required)</IonText>
                  </div>
                </IonInput>
              </IonItem>
            </IonList>

            <div className="ion-padding">
              <IonButton type="submit" color="primary" disabled={isSaving}>
                Submit
                {isSaving && (
                  <span className="ml-2">
                    <Spinner
                      color={"success"}
                      size="sm"
                      aria-label="Saving..."
                    />
                  </span>
                )}
              </IonButton>
              <IonButton
                color="danger"
                fill="clear"
                onClick={() => setIsOpen(false)}
                disabled={isSaving}
              >
                Cancel
              </IonButton>
            </div>
          </form>
        </IonContent>
      </IonModal>
    </>
  );
};

// const EditUserPermissionsModal: FC<{
//   userAccountTenantPermission: UserAccountTenantPermission;
//   setErrorData: React.Dispatch<React.SetStateAction<Error>>;
//   setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
// }> = ({ userAccountTenantPermission, setErrorData, setErrorModalIsOpen }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   async function updateTenantPermission(
//     userAccountTenantPermission: UserAccountTenantPermission
//   ) {
//     try {
//       await UserAccountTenantPermissionService.saveOne(
//         userAccountTenantPermission,
//         false
//       );
//       setIsOpen(false);
//     } catch (err) {
//       setIsOpen(false);
//       setErrorData(err);
//       setErrorModalIsOpen(true);
//       throw err;
//     }
//   }

//   return (
//     <>
//       <IonButton size="small" color="primary" onClick={() => setIsOpen(true)}>
//         <div className="flex items-center gap-x-2">
//           <HiOutlinePencilAlt className="text-lg" />
//           Edit Permissions
//         </div>
//       </IonButton>
//       <IonModal
//         isOpen={isOpen}
//         onDidDismiss={() => setIsOpen(false)}
//         backdropDismiss={false}
//       >
//         <IonHeader>
//           <IonToolbar>
//             <IonTitle>
//               <strong>Edit Permissions</strong>
//             </IonTitle>
//             <IonButtons slot="end">
//               <IonButton onClick={() => setIsOpen(false)} color="primary">
//                 <IonIcon slot="icon-only" icon={closeOutline} />
//               </IonButton>
//             </IonButtons>
//           </IonToolbar>
//         </IonHeader>
//         <IonContent>
//           <p>Not available at this time.</p>
//         </IonContent>
//       </IonModal>
//     </>
//   );
// };

const RemoveUserPermissionModal: FC<{
  userAccountTenantPermission: UserAccountTenantPermission;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ userAccountTenantPermission, setErrorData, setErrorModalIsOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  async function deleteTenantPermission(
    userAccountTenantPermission: UserAccountTenantPermission
  ) {
    try {
      await UserAccountTenantPermissionService.deleteOne(
        userAccountTenantPermission.id
      );
      setIsOpen(false);
    } catch (err) {
      setIsOpen(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      <IonButton size="small" color="danger" onClick={() => setIsOpen(true)}>
        <div className="flex items-center gap-x-2">
          <HiX className="text-lg" />
          Remove Permissions
        </div>
      </IonButton>

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              <strong>Remove Tenant Permissions</strong>
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)} color="primary">
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <p className="ion-padding">
            Are you sure you want to remove tenant permissions for{" "}
            {userAccountTenantPermission.firstName}{" "}
            {userAccountTenantPermission.lastName} (
            {userAccountTenantPermission.emailAddress})?
          </p>
          <div className="ion-padding">
            <IonButton
              color="danger"
              onClick={() =>
                deleteTenantPermission(userAccountTenantPermission)
              }
            >
              Yes, I'm sure
            </IonButton>
            <IonButton fill="clear" onClick={() => setIsOpen(false)}>
              No, cancel
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

// export const Pagination: FC = function () {
//   return (
//     <div className="sticky bottom-0 right-0 w-full items-center border-t border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex sm:justify-between">
//       <div className="mb-4 flex items-center sm:mb-0">
//         <a
//           href="#"
//           className="inline-flex cursor-pointer justify-center rounded p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
//         >
//           <span className="sr-only">Previous page</span>
//           <HiChevronLeft className="text-2xl" />
//         </a>
//         <a
//           href="#"
//           className="mr-2 inline-flex cursor-pointer justify-center rounded p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
//         >
//           <span className="sr-only">Next page</span>
//           <HiChevronRight className="text-2xl" />
//         </a>
//         <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
//           Showing&nbsp;
//           <span className="font-semibold text-gray-900 dark:text-white">
//             1-20
//           </span>
//           &nbsp;of&nbsp;
//           <span className="font-semibold text-gray-900 dark:text-white">
//             2290
//           </span>
//         </span>
//       </div>
//       <div className="flex items-center space-x-3">
//         <a
//           href="#"
//           className="inline-flex flex-1 items-center justify-center rounded-lg bg-primary-700 px-3 py-2 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
//         >
//           <HiChevronLeft className="mr-1 text-base" />
//           Previous
//         </a>
//         <a
//           href="#"
//           className="inline-flex flex-1 items-center justify-center rounded-lg bg-primary-700 px-3 py-2 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
//         >
//           Next
//           <HiChevronRight className="ml-1 text-base" />
//         </a>
//       </div>
//     </div>
//   );
// };

export default TenantUsersPage;
