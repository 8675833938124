import { auth } from "../firebase/firebase";
import {
  User,
  UserCredential,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInAnonymously,
  getRedirectResult,
  sendEmailVerification,
} from "firebase/auth";
import UserAccountService from "./user-account.service";

function getCurrentFirebaseUser(): User {
  return auth.currentUser;
}

function refreshUserAuth(): Promise<void> {
  return auth.currentUser.reload();
}

/**
 * Send password reset email from firebase
 * @param email
 */
async function resetPassword(email): Promise<boolean> {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (err) {
    throw err;
  }
}

/**
 * Register a user with their email and password
 * @param email
 * @param password
 */
async function emailRegistration(
  email: string,
  password: string,
  firstName: string,
  lastName: string
): Promise<User> {
  try {
    const userCredential: UserCredential = await createUserWithEmailAndPassword(
      auth,
      email.toLowerCase(),
      password
    );
    // const authCredential: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(email, password);
    // const userCredential: firebase.auth.UserCredential = await firebase.auth().currentUser.linkWithCredential(authCredential);
    // await UserAccountService.saveUserRecordWithLoginCredential(userCredential, undefined, firstName, lastName);

    await UserAccountService.saveOneWithCredential(
      userCredential,
      firstName,
      lastName
    );

    //send user email address verification email message
    await sendEmailVerification(userCredential.user);

    return userCredential.user;
  } catch (err) {
    throw err;
    throw err;
  }
}

/**
 * Attempt login with email and password
 * @param email
 * @param password
 */
async function attemptEmailLogin(
  email: string,
  password: string
): Promise<User> {
  try {
    // const prevUser: firebase.User = firebase.auth().currentUser;
    // const authCredential: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(email, password);
    // const userCredential: firebase.auth.UserCredential = await firebase.auth().signInWithCredential(authCredential);
    const userCredential: UserCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    await UserAccountService.saveOneWithCredential(userCredential);

    return userCredential.user;
  } catch (err) {
    throw err;
  }
}

async function attemptAnonymousLogin(): Promise<User> {
  try {
    const userCredential: UserCredential = await signInAnonymously(auth);
    await UserAccountService.saveOneWithCredential(userCredential);

    return userCredential.user;
  } catch (err) {
    throw err;
  }
}

/**
 * Get the result from a Google, Facebook, or any other third-party auth service after redirect
 */
async function getRedirectAuth(): Promise<boolean> {
  try {
    const userCredential: UserCredential = await getRedirectResult(auth);

    if (userCredential) {
      await UserAccountService.saveOneWithCredential(userCredential);
      return true;
    } else {
      return false;
    }
  } catch (err) {
    throw err;
  }
}

/**
 * Used by the http interceptor to set the auth header
 */
async function getUserIdToken(): Promise<string> {
  try {
    const user: User = auth.currentUser;
    if (user) {
      const token: string = await user.getIdToken();
      return token;
    } else {
      return null;
    }
  } catch (err) {
    throw err;
  }
}

const AuthService = {
  auth,
  getCurrentFirebaseUser,
  refreshUserAuth,
  resetPassword,
  sendEmailVerification,
  emailRegistration,
  attemptEmailLogin,
  attemptAnonymousLogin,
  getRedirectAuth,
  getUserIdToken,
};

export default AuthService;
