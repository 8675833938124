/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Table } from "flowbite-react";
import { useEffect, type FC, useState } from "react";
import ErrorModal from "../../components/modals/error-modal";

import { useBrandContext } from "../../context/brand.context";
import { combineLatest } from "rxjs";
import SetService from "../../services/set.service";
import { Set } from "../../models/set";
import { auth } from "../../firebase/firebase";
import { HiChevronDoubleRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import EditSetButtonAndModal from "../../components/modals/edit-set-button-and-modal";
import DeleteSetButtonAndModal from "../../components/modals/delete-set-button-and-modal";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Breadcrumbs from "../../components/Breadcrumbs";
import SpinnerCentered from "../../components/SpinnerCentered";

const SetsPage: FC = function () {
  const brand = useBrandContext();
  const pageTitle = "Sets";

  // Error Message States
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [sets, setSets] = useState<Set[]>([]);
  const [filteredSets, setFilteredSets] = useState<Set[]>([]);

  useEffect(() => {
    const subscription = combineLatest([
      SetService.getAllByBrand(auth.tenantId, brand.id),
    ]).subscribe({
      next: ([sets]) => {
        setSets(sets);
        setFilteredSets(sets);
        setIsLoading(false);
      },
      error: (err) => {
        setIsLoading(false);
        setErrorData(err);
        setErrorModalIsOpen(true);
        throw err;
      },
      complete: () => console.info("complete"),
    });

    return () => subscription.unsubscribe();
  }, []);

  function handleSearch(searchVal: string) {
    if (searchVal === "") {
      setFilteredSets(sets);
      return;
    }

    const filterBySearch = sets.filter((set) => {
      if (JSON.stringify(set).toLowerCase().includes(searchVal.toLowerCase())) {
        return set;
      } else {
        return undefined;
      }
    });

    setFilteredSets(filterBySearch);
  }

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
          <IonButtons slot="end">
            <EditSetButtonAndModal
              buttonCopy={"New Set"}
              setId={undefined}
              setErrorData={setErrorData}
              setErrorModalIsOpen={setErrorModalIsOpen}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {isLoading ? (
          <SpinnerCentered />
        ) : (
          <>
            <Breadcrumbs data={[{ name: "Sets" }]}></Breadcrumbs>

            <IonSearchbar
              id="search"
              name="search"
              placeholder="Search"
              debounce={500}
              onIonInput={(ev) => handleSearch(ev.detail.value)}
            ></IonSearchbar>

            <div className="m-4 overflow-x-auto">
              <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                <Table.Head className="bg-gray-100 dark:bg-gray-700">
                  <Table.HeadCell>Photo</Table.HeadCell>
                  <Table.HeadCell>Set Name</Table.HeadCell>
                  <Table.HeadCell>Max Products</Table.HeadCell>
                  <Table.HeadCell>Max Videos</Table.HeadCell>
                  <Table.HeadCell>Max Questions</Table.HeadCell>
                  <Table.HeadCell></Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
                  {filteredSets.map((set) => (
                    <Table.Row
                      className="hover:bg-gray-100 dark:hover:bg-gray-700"
                      key={set.id}
                    >
                      <Table.Cell>
                        {set.setImageUrl && (
                          <img
                            src={set.setImageUrl}
                            className="w-32 aspect-square"
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                        <div className="text-base underline font-semibold text-gray-900 dark:text-white">
                          <Link to={`/brand/${brand.id}/sets/${set.id}`}>
                            {set.setName}
                          </Link>
                        </div>
                        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                          {set.setDescription}
                        </div>
                      </Table.Cell>
                      <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                        {set.maxProductCount || 0}
                      </Table.Cell>
                      <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                        {set.maxVideoCount || 0}
                      </Table.Cell>
                      <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                        {set.maxQuestionCount || 0}
                      </Table.Cell>
                      <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                        <div className="inline-block m-2">
                          <IonButton
                            routerDirection="forward"
                            routerLink={`/brand/${brand.id}/sets/${set.id}`}
                            size="small"
                            color="primary"
                          >
                            Manage
                            <HiChevronDoubleRight className="ml-1 h-3 w-3" />
                          </IonButton>
                        </div>
                        <div className="inline-block m-2">
                          <EditSetButtonAndModal
                            buttonCopy={"Edit"}
                            setId={set.id}
                            setErrorData={setErrorData}
                            setErrorModalIsOpen={setErrorModalIsOpen}
                          />
                        </div>
                        <div className="inline-block m-2">
                          <DeleteSetButtonAndModal
                            buttonCopy={"Delete"}
                            setId={set.id}
                            setErrorData={setErrorData}
                            setErrorModalIsOpen={setErrorModalIsOpen}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>

            {sets.length === 0 && (
              <div className="m-4">
                <p>Create your first set by clicking the "New Set" button.</p>
              </div>
            )}
          </>
        )}

        <ErrorModal
          errorData={errorData}
          errorModalIsOpen={errorModalIsOpen}
          setErrorModalIsOpen={setErrorModalIsOpen}
        />
      </IonContent>
    </IonPage>
  );
};

export default SetsPage;
