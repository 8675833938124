import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { combineLatest, firstValueFrom } from "rxjs";
import { Button, ListGroup, Modal } from "flowbite-react";
import { auth } from "../../firebase/firebase";
import { HiPlus } from "react-icons/hi";
import { Store } from "../../models/store";
import StoreService from "../../services/store.service";
import { IonButton } from "@ionic/react";

const SelectStoreButtonAndModal: FC<{
  buttonCopy?: string | undefined;
  selection: (id: string) => void;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ buttonCopy, selection, setErrorData, setErrorModalIsOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const brand = useBrandContext();

  const [stores, setStores] = useState<Store[]>([]);

  useEffect(() => {
    if (isOpen) {
      const subscription = combineLatest([
        StoreService.getAllByBrand(auth.tenantId, brand.id),
      ]).subscribe({
        next: ([stores]) => {
          setStores(stores);
        },
        error: (err) => {
          setErrorData(err);
          setErrorModalIsOpen(true);
          throw err;
        },
        complete: () => console.info("complete"),
      });

      return () => subscription.unsubscribe();
    }
    return undefined;
  }, [isOpen]);

  function onSelect(id: any) {
    selection(id);
    setIsOpen(false);
  }

  return (
    <>
      <IonButton size="small" color="success" onClick={() => setIsOpen(true)}>
        <HiPlus className="mr-1 h-3 w-3" />
        {buttonCopy ? buttonCopy : "Select a Store"}
      </IonButton>

      <Modal onClose={() => setIsOpen(false)} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Select a Store</strong>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "60vh" }}>
          {stores && stores.length > 0 ? (
            <ListGroup className="w-full">
              {stores.map((store) => (
                <ListGroup.Item
                  key={store.id}
                  onClick={() => onSelect(store.id)}
                >
                  {store.storeName}
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p>No stores found.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <IonButton
            size="small"
            color="danger"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </IonButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectStoreButtonAndModal;
