import {
  DocumentData,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, of, map, firstValueFrom } from "rxjs";
import { Brand } from "../models/brand";
import { UserAccountBrandPermission } from "../models/user-account-brand-permission";
import UserAccountBrandPermissionService from "./user-account-brand-permission.service";
import DataTransformationService from "./data-transformation.service";

/**
 * Generate a new brand id
 * @returns
 */
function generateId(): string {
  return doc(collection(db, "brands")).id;
}

/**
 * Get a brand by id
 * @param id
 * @returns
 */
function getOne(id?: string): Observable<Brand | undefined> {
  if (id) {
    return docData(doc(db, `brands/${id}`));
  } else {
    return of(undefined);
  }
}

/**
 * Get all brands for a user account
 * @param tenantId
 * @param userAccountId
 * @returns
 */
async function getAllByUserAccountBrandPermission(
  tenantId: string,
  userAccountId: string
): Promise<Brand[]> {
  try {
    const userAccountBrandPermissions: UserAccountBrandPermission[] =
      await firstValueFrom(
        UserAccountBrandPermissionService.getAllForUserAccount(
          tenantId,
          userAccountId
        ),
        { defaultValue: [] }
      );
    const brands: Brand[] = [];

    if (Array.isArray(userAccountBrandPermissions)) {
      for (const userAccountBrandPermission of userAccountBrandPermissions) {
        const docRef = doc(db, `brands/${userAccountBrandPermission.brandId}`);
        brands.push(
          await firstValueFrom(
            docData(docRef).pipe(
              map((firestoreDocument) =>
                DataTransformationService.convertTimestampsToIsoString(
                  firestoreDocument
                )
              )
            )
          )
        );
      }
    }
    return brands;
  } catch (err) {
    throw err;
  }
}

/**
 * Get all brands for a tenant
 * @param tenantId
 * @returns
 */
function getAllByTenant(tenantId: string): Observable<Brand[]> {
  const collectionRef = collection(db, "brands");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) =>
          (a["createdDate"] && a["createdDate"] instanceof Timestamp
            ? a["createdDate"].seconds
            : 99999999999) -
          (b["createdDate"] && b["createdDate"] instanceof Timestamp
            ? b["createdDate"].seconds
            : 99999999999)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

/**
 * Save a brand
 * @param brand
 * @param isNew
 * @returns
 */
async function saveOne(brand: Partial<Brand>, isNew: boolean): Promise<any> {
  try {
    if (isNew) {
      brand.createdDate = serverTimestamp();
      brand.createdByUserAccountId = auth.currentUser?.uid;
    } else {
      brand.updatedDate = serverTimestamp();
      brand.updatedByUserAccountId = auth.currentUser?.uid;
    }

    if (brand.deleted == undefined) brand.deleted = false;

    brand = DataTransformationService.convertDatesToFirestoreTimestamp(brand);

    let docRef = doc(db, `brands/${brand.id}`);
    await setDoc(docRef, brand, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

/**
 * Delete a brand
 * @param brand
 * @returns
 */
async function deleteOne(brand: Brand): Promise<any> {
  try {
    let docRef = doc(db, `brands/${brand.id}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser?.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

const BrandService = {
  generateId,
  getOne,
  getAllByUserAccountBrandPermission,
  getAllByTenant,
  saveOne,
  deleteOne,
};

export default BrandService;
