/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button, Spinner, Table } from "flowbite-react";
import { useEffect, type FC, useState } from "react";

import { combineLatest } from "rxjs";
import ErrorModal from "../../components/modals/error-modal";
import StoreService from "../../services/store.service";
import { useBrandContext } from "../../context/brand.context";
import { auth } from "../../firebase/firebase";
import { Store } from "../../models/store";
import ProductService from "../../services/product.service";
import { Brand } from "../../models/brand";
import { Accordion } from "flowbite-react";
import { Product } from "../../models/product";
import { HiChevronDoubleRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import DeleteStoreButtonAndModal from "../../components/modals/delete-store-button-and-modal";
import EditStoreButtonAndModal from "../../components/modals/edit-store-button-and-modal";
import EditProductButtonAndModal from "../../components/modals/edit-product-button-and-modal";
import DeleteProductButtonAndModal from "../../components/modals/delete-product-button-and-modal";
import { FaCheckCircle } from "react-icons/fa";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Breadcrumbs from "../../components/Breadcrumbs";
import SpinnerCentered from "../../components/SpinnerCentered";

const StoresPage: FC = function () {
  const brand = useBrandContext();
  const pageTitle = "Stores & Products";

  // Error Message States
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [stores, setStores] = useState<Store[]>([]);

  useEffect(() => {
    const subscription = combineLatest([
      StoreService.getAllByBrand(String(auth.tenantId), String(brand.id)),
    ]).subscribe({
      next: ([stores]) => {
        setStores(stores);
        setIsLoading(false);
      },
      error: (err) => {
        setIsLoading(false);
        setErrorData(err);
        setErrorModalIsOpen(true);
        throw err;
      },
      complete: () => console.info("complete"),
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
          <IonButtons slot="end">
            <EditStoreButtonAndModal
              buttonCopy={"New Store"}
              storeId={undefined}
              setErrorData={setErrorData}
              setErrorModalIsOpen={setErrorModalIsOpen}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {isLoading ? (
          <SpinnerCentered />
        ) : (
          <>
            <Breadcrumbs data={[{ name: "Sets" }]}></Breadcrumbs>
            <p className="ion-padding">
              Experiences are associated with stores of products. Expand a store
              to view the products. Select an product to view its experiences.
            </p>

            {stores.length === 0 && (
              <div className="m-4">
                <p>
                  Create your first Store by clicking the "New Store" button.
                </p>
              </div>
            )}
            <div className="m-4">
              <Accordion collapseAll>
                {stores.map((store) => (
                  <Accordion.Panel key={store.id}>
                    <Accordion.Title>{store.storeName}</Accordion.Title>
                    <Accordion.Content>
                      <div className="sm:flex w-full mb-4">
                        <p className="text-sm font-semibold">
                          {store.storeImageUrl && (
                            <img
                              src={store.storeImageUrl}
                              className="w-32 float-right"
                            />
                          )}
                          Store Type: {store.storeType}
                          <br />
                          Store URL: {store.storeUrl}
                          <br />
                          Website supports iFrame embed:{" "}
                          {store.storeWebsiteSupportsIFrameEmbed == true
                            ? "Yes"
                            : "No"}
                          <br />
                          Store Currency: {store.storeCurrency}
                          <br />
                          Supports Experience Shopping Cart:{" "}
                          {store.supportsExperienceShoppingCart == true
                            ? "Yes"
                            : "No"}
                          <br />
                          Supports Experience Shopping List:{" "}
                          {store.supportsExperienceShoppingList == true
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                      <div className="sm:flex w-full mb-4">
                        <div className="inline-block mr-4">
                          <EditStoreButtonAndModal
                            storeId={store.id}
                            setErrorData={setErrorData}
                            setErrorModalIsOpen={setErrorModalIsOpen}
                          />
                        </div>
                        <div className="inline-block mr-4">
                          <DeleteStoreButtonAndModal
                            storeId={store.id}
                            setErrorData={setErrorData}
                            setErrorModalIsOpen={setErrorModalIsOpen}
                          />
                        </div>
                      </div>
                      <div className="sm:flex w-full mb-4">
                        <h2 className="text-lg font-semibold text-gray-900 dark:text-white sm:text-lg inline-block">
                          Products
                        </h2>
                        <div className="inline-block ml-4">
                          <EditProductButtonAndModal
                            productId={undefined}
                            store={store}
                            setErrorData={setErrorData}
                            setErrorModalIsOpen={setErrorModalIsOpen}
                          />
                        </div>
                      </div>

                      <ProductsTable
                        store={store}
                        brand={brand}
                        setErrorData={setErrorData}
                        setErrorModalIsOpen={setErrorModalIsOpen}
                      />
                    </Accordion.Content>
                  </Accordion.Panel>
                ))}
              </Accordion>
            </div>
          </>
        )}

        <ErrorModal
          errorData={errorData}
          errorModalIsOpen={errorModalIsOpen}
          setErrorModalIsOpen={setErrorModalIsOpen}
        />
      </IonContent>
    </IonPage>
  );
};

const ProductsTable: FC<{
  store: Store;
  brand: Brand;
  setErrorData: React.Dispatch<React.SetStateAction<Error | undefined>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ store, brand, setErrorData, setErrorModalIsOpen }) => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const subscription = combineLatest([
      ProductService.getAllByStoreIds(auth.tenantId, brand.id, [store.id]),
    ]).subscribe({
      next: ([products]) => {
        setProducts(products);
      },
      error: (err) => {
        setErrorData(err);
        setErrorModalIsOpen(true);
        throw err;
      },
      complete: () => console.info("complete"),
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      <div className="overflow-x-auto">
        <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
          <Table.Head className="bg-gray-100 dark:bg-gray-700">
            <Table.HeadCell>Image</Table.HeadCell>
            <Table.HeadCell>3D Model</Table.HeadCell>
            <Table.HeadCell>Product Name</Table.HeadCell>
            <Table.HeadCell>Type</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
            {products.map((product) => (
              <Table.Row
                className="hover:bg-gray-100 dark:hover:bg-gray-700"
                key={product.id}
              >
                <Table.Cell>
                  {product.productImageUrl && (
                    <img
                      src={product.productImageUrl}
                      className="w-32 aspect-square"
                    />
                  )}
                </Table.Cell>
                <Table.Cell>
                  {product.productModelUrl && (
                    <FaCheckCircle className="text-green-500" />
                  )}
                </Table.Cell>
                <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                  <div className="text-base underline font-semibold text-gray-900 dark:text-white">
                    <Link
                      to={`/brand/${brand.id}/stores/product/${product.id}`}
                    >
                      {product.productName}
                    </Link>
                  </div>
                  <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {product.productDescription}
                  </div>
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  {product.productType}
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  <div className="inline-block m-2">
                    <IonButton
                      routerDirection="forward"
                      routerLink={`/brand/${brand.id}/stores/product/${product.id}`}
                      size="small"
                      color="primary"
                    >
                      Manage
                      <HiChevronDoubleRight className="ml-1 h-3 w-3" />
                    </IonButton>
                  </div>
                  <div className="inline-block m-2">
                    <EditProductButtonAndModal
                      buttonCopy={"Edit"}
                      productId={product.id}
                      store={store}
                      setErrorData={setErrorData}
                      setErrorModalIsOpen={setErrorModalIsOpen}
                    />
                  </div>
                  <div className="inline-block m-2">
                    <DeleteProductButtonAndModal
                      buttonCopy={"Delete"}
                      productId={product.id}
                      setErrorData={setErrorData}
                      setErrorModalIsOpen={setErrorModalIsOpen}
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      {products.length === 0 && (
        <div className="m-4">
          <p>Create your first Product by clicking the "New Product" button.</p>
        </div>
      )}
    </>
  );
};

export default StoresPage;
