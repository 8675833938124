import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { useForm, Controller } from "react-hook-form";
import { firstValueFrom } from "rxjs";
import { Spinner } from "flowbite-react";
import { Event } from "../../models/event";
import { auth } from "../../firebase/firebase";
import { HiPencilAlt, HiPlus } from "react-icons/hi";
import { Group } from "../../models/group";
import { Experience } from "../../models/experience";
import ExperienceService from "../../services/experience.service";
import { DateTime } from "luxon";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  calendarOutline,
  closeOutline,
  time,
  timeOutline,
} from "ionicons/icons";

const EditExperienceButtonAndModal: FC<{
  buttonCopy?: string | undefined;
  experienceId: string | undefined;
  group: Group;
  event: Event;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  buttonCopy,
  experienceId,
  group,
  event,
  setErrorData,
  setErrorModalIsOpen,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { register, handleSubmit, setValue, control, watch } = useForm({
    defaultValues: {
      id: null,
      tenantId: null,
      brandId: null,
      groupId: null,
      eventId: null,
      experienceName: null,
      timecodeStart: null,
      timecodeEnd: null,
      experienceStartDate: null,
      experienceEndDate: null,
    },
  });
  const brand = useBrandContext();

  useEffect(() => {
    // Get the experience data if the modal is open
    async function getData() {
      if (isOpen) {
        setIsLoading(true);

        if (experienceId) {
          const experience = await firstValueFrom(
            ExperienceService.getOne(experienceId)
          );
          console.log(experience);
          updateFormValues(experience);
        } else {
          const newExperience: Partial<Experience> = {
            id: ExperienceService.generateId(),
            tenantId: auth.tenantId,
            brandId: brand.id,
            groupId: group.id,
            eventId: event.id,
          };
          updateFormValues(newExperience);
        }

        setIsLoading(false);
      }
      return;
    }

    getData().catch((err) => {
      setIsLoading(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    });
  }, [isOpen, experienceId]);

  // Update the form values when the experience is loaded
  function updateFormValues(experience: Experience) {
    setValue("id", experience.id);
    setValue("tenantId", experience.tenantId);
    setValue("brandId", experience.brandId);
    setValue("groupId", experience.groupId);
    setValue("eventId", experience.eventId);
    setValue("experienceName", experience.experienceName || null);
    setValue("timecodeStart", experience.timecodeStart || null);
    setValue("timecodeEnd", experience.timecodeEnd || null);
    setValue("experienceStartDate", experience.experienceStartDate || null);
    setValue("experienceEndDate", experience.experienceEndDate || null);
  }

  async function onSubmit(formData: any) {
    try {
      setIsSaving(true);

      await ExperienceService.saveOne(formData, experienceId ? false : true);
      setIsSaving(false);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      {experienceId ? (
        <IonButton
          size="small"
          fill="solid"
          color="warning"
          onClick={() => setIsOpen(true)}
        >
          <HiPencilAlt className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "Edit 3D Experience"}
        </IonButton>
      ) : (
        <IonButton
          size="small"
          fill="solid"
          color="success"
          onClick={() => setIsOpen(true)}
        >
          <HiPlus className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "New 3D Experience"}
        </IonButton>
      )}

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {experienceId ? (
                <strong>Edit 3D Experience</strong>
              ) : (
                <strong>New 3D Experience</strong>
              )}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)} color="primary">
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {isLoading ? (
            <Spinner color="success" />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList>
                <IonItem>
                  <IonInput
                    {...register("experienceName")}
                    labelPlacement="floating"
                  >
                    <div slot="label">
                      Experience name{" "}
                      <IonText color="danger">(Required)</IonText>
                    </div>
                  </IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel>Experience Start Date</IonLabel>
                  <Controller
                    control={control}
                    name="experienceStartDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          {/* Date and Time selection buttons */}
                          <IonDatetimeButton datetime="datetime_experienceStartDate">
                            {!value && (
                              <>
                                <IonIcon
                                  icon={calendarOutline}
                                  slot="date-target"
                                ></IonIcon>
                                <IonIcon
                                  icon={timeOutline}
                                  slot="time-target"
                                ></IonIcon>
                              </>
                            )}
                          </IonDatetimeButton>

                          {/* Date and Time selection pop-over */}
                          <IonPopover keepContentsMounted={true}>
                            <IonDatetime
                              id="datetime_experienceStartDate"
                              minuteValues="0,15,30,45"
                              max={DateTime.now().plus({ years: 10 }).toISO()}
                              min={DateTime.now().minus({ years: 10 }).toISO()}
                              onIonChange={(e) => onChange(e.detail.value)}
                              value={value}
                            ></IonDatetime>
                          </IonPopover>

                          {/* Clear Date Button */}
                          {value && (
                            <IonButton
                              fill="clear"
                              color="danger"
                              onClick={() => onChange(null)}
                              slot="end"
                            >
                              <IonIcon icon={closeOutline} />
                            </IonButton>
                          )}
                        </>
                      );
                    }}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel>Experience End Date</IonLabel>
                  <Controller
                    control={control}
                    name="experienceEndDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          {/* Date and Time selection buttons */}
                          <IonDatetimeButton datetime="datetime_experienceEndDate">
                            {!value && (
                              <>
                                <IonIcon
                                  icon={calendarOutline}
                                  slot="date-target"
                                ></IonIcon>
                                <IonIcon
                                  icon={timeOutline}
                                  slot="time-target"
                                ></IonIcon>
                              </>
                            )}
                          </IonDatetimeButton>

                          {/* Date and Time selection pop-over */}
                          <IonPopover keepContentsMounted={true}>
                            <IonDatetime
                              id="datetime_experienceEndDate"
                              minuteValues="0,15,30,45"
                              max={DateTime.now().plus({ years: 10 }).toISO()}
                              min={DateTime.now().minus({ years: 10 }).toISO()}
                              onIonChange={(e) => onChange(e.detail.value)}
                              value={value}
                            ></IonDatetime>
                          </IonPopover>

                          {/* Clear Date Button */}
                          {value && (
                            <IonButton
                              fill="clear"
                              color="danger"
                              onClick={() => onChange(null)}
                              slot="end"
                            >
                              <IonIcon icon={closeOutline} />
                            </IonButton>
                          )}
                        </>
                      );
                    }}
                  />
                </IonItem>

                <IonItem>
                  <IonInput
                    {...register("timecodeStart")}
                    labelPlacement="floating"
                  >
                    <div slot="label">Starting Timecode</div>
                  </IonInput>
                </IonItem>
                <IonItem>
                  <IonInput
                    {...register("timecodeEnd")}
                    labelPlacement="floating"
                  >
                    <div slot="label">Ending Timecode</div>
                  </IonInput>
                </IonItem>
                {experienceId ? (
                  <p className="ion-padding">
                    NOTE: Sets, products, placements and more can be configured
                    when viewing the experience.
                  </p>
                ) : (
                  <p className="ion-padding">
                    NOTE: Additional configuration can be completed once the
                    experience is created.
                  </p>
                )}
              </IonList>

              <div className="ion-padding">
                <IonButton type="submit" color="primary" disabled={isSaving}>
                  Save
                  {isSaving && (
                    <span className="ml-2">
                      <Spinner
                        color={"success"}
                        size="sm"
                        aria-label="Saving..."
                      />
                    </span>
                  )}
                </IonButton>
                <IonButton
                  color="danger"
                  fill="clear"
                  onClick={() => setIsOpen(false)}
                  disabled={isSaving}
                >
                  Cancel
                </IonButton>
              </div>
            </form>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default EditExperienceButtonAndModal;
