import { FC, useEffect } from "react";
import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

const ModelViewer: FC<{
  modelFileUrl: string;
}> = ({ modelFileUrl }) => {
  useEffect(() => {
    // Preload the model
    useGLTF.preload(modelFileUrl);
  }, []);

  function Model({ url }) {
    const gltf = useGLTF(url, true);

    if ("scene" in gltf) {
      return <primitive object={gltf.scene} scale={[100, 100, 100]} />;
    } else {
      return null;
    }
  }

  return (
    <Canvas style={{ height: "400px" }}>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Model url={modelFileUrl} />
      <OrbitControls />
    </Canvas>
  );
};

export default ModelViewer;
