import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { Controller, useForm } from "react-hook-form";
import { firstValueFrom } from "rxjs";
import EventService from "../../services/event.service";
import { Spinner } from "flowbite-react";
import { Event } from "../../models/event";
import { auth } from "../../firebase/firebase";
import { HiPencilAlt, HiPlus } from "react-icons/hi";
import { Group } from "../../models/group";
import StoreService from "../../services/store.service";
import { Store } from "../../models/store";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

const EditEventButtonAndModal: FC<{
  buttonCopy?: string | undefined;
  eventId: string | undefined;
  group: Group;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ buttonCopy, eventId, group, setErrorData, setErrorModalIsOpen }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [stores, setStores] = useState<Store[]>([]);
  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      id: null,
      tenantId: null,
      brandId: null,
      groupId: null,
      eventName: null,
      storeIds: [],
      shoppingCartStoreId: "",
      requireRegistrationBeforeExperience: false,
      requireRegistrationBeforeAnsweringQuestion: false,
      userRegistrationFieldRequirements_firstName: "notUsed",
      userRegistrationFieldRequirements_lastName: "notUsed",
      userRegistrationFieldRequirements_emailAddress: "notUsed",
      userRegistrationFieldRequirements_phoneNumber: "notUsed",
      userRegistrationFieldRequirements_postcode: "notUsed",
      userRegistrationFieldRequirements_gender: "notUsed",
      userRegistrationFieldRequirements_dateOfBirth: "notUsed",
      userRegistrationFieldRequirements_optInMarketing: "notUsed",
    },
  });
  const brand = useBrandContext();

  useEffect(() => {
    // Get the event data if the modal is open
    async function getData() {
      if (isOpen) {
        setIsLoading(true);
        const stores = await firstValueFrom(
          StoreService.getAllByBrand(auth.tenantId, brand.id)
        );
        setStores(stores);

        if (eventId) {
          const event = await firstValueFrom(EventService.getOne(eventId));
          updateFormValues(event);
        } else {
          const newEvent: Partial<Event> = {
            id: EventService.generateId(),
            tenantId: auth.tenantId,
            brandId: brand.id,
            groupId: group.id,
          };
          updateFormValues(newEvent);
        }

        setIsLoading(false);
      }
      return;
    }

    getData().catch((err) => {
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    });
  }, [isOpen, eventId]);

  // Update the form values when the event is loaded
  function updateFormValues(event: Event) {
    setValue("id", event.id);
    setValue("tenantId", event.tenantId);
    setValue("brandId", event.brandId);
    setValue("groupId", event.groupId);
    setValue("eventName", event.eventName || null);
    setValue("storeIds", event.storeIds || []);
    setValue("shoppingCartStoreId", event.shoppingCartStoreId || "");
    setValue(
      "requireRegistrationBeforeExperience",
      event.requireRegistrationBeforeExperience || false
    );
    setValue(
      "requireRegistrationBeforeAnsweringQuestion",
      event.requireRegistrationBeforeAnsweringQuestion || false
    );
    setValue(
      "userRegistrationFieldRequirements_firstName",
      event.userRegistrationFieldRequirements_firstName || "notUsed"
    );
    setValue(
      "userRegistrationFieldRequirements_lastName",
      event.userRegistrationFieldRequirements_lastName || "notUsed"
    );
    setValue(
      "userRegistrationFieldRequirements_emailAddress",
      event.userRegistrationFieldRequirements_emailAddress || "notUsed"
    );
    setValue(
      "userRegistrationFieldRequirements_phoneNumber",
      event.userRegistrationFieldRequirements_phoneNumber || "notUsed"
    );
    setValue(
      "userRegistrationFieldRequirements_postcode",
      event.userRegistrationFieldRequirements_postcode || "notUsed"
    );
    setValue(
      "userRegistrationFieldRequirements_gender",
      event.userRegistrationFieldRequirements_gender || "notUsed"
    );
    setValue(
      "userRegistrationFieldRequirements_dateOfBirth",
      event.userRegistrationFieldRequirements_dateOfBirth || "notUsed"
    );
    setValue(
      "userRegistrationFieldRequirements_optInMarketing",
      event.userRegistrationFieldRequirements_optInMarketing || "notUsed"
    );
  }

  async function onSubmit(formData: any) {
    try {
      setIsSaving(true);
      await EventService.saveOne(formData, eventId ? false : true);
      setIsSaving(false);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      {eventId ? (
        <IonButton
          size="small"
          fill="solid"
          color="warning"
          onClick={() => setIsOpen(true)}
        >
          <HiPencilAlt className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "Edit Event"}
        </IonButton>
      ) : (
        <IonButton
          size="small"
          fill="solid"
          color="success"
          onClick={() => setIsOpen(true)}
        >
          <HiPlus className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "New Event"}
        </IonButton>
      )}

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {eventId ? (
                <strong>Edit Event</strong>
              ) : (
                <strong>New Event</strong>
              )}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)} color="primary">
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {isLoading ? (
            <Spinner color="success" />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList>
                <IonItem>
                  <IonInput
                    {...register("eventName")}
                    labelPlacement="floating"
                  >
                    <div slot="label">
                      Event name <IonText color="danger">(Required)</IonText>
                    </div>
                  </IonInput>
                </IonItem>

                <IonItem>
                  <IonSelect
                    labelPlacement="stacked"
                    {...register("shoppingCartStoreId")}
                  >
                    <div slot="label">
                      Store used for in-experience shopping cart{" "}
                      <IonText color="danger">(Required)</IonText>
                    </div>
                    <IonSelectOption value={""}>
                      No in-experience shopping cart used.
                    </IonSelectOption>
                    {stores.map((store) => (
                      <IonSelectOption key={store.id} value={store.id}>
                        {store.storeName} ({store.storeType})
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>

                <IonItemDivider sticky={true}>
                  Require User Registration
                </IonItemDivider>
                <IonItem>
                  <Controller
                    control={control}
                    name="requireRegistrationBeforeExperience"
                    render={({ field: { onChange, value } }) => (
                      <IonToggle
                        checked={value}
                        onIonChange={(e) => onChange(e.detail.checked)}
                      >
                        Require User Registration Before Experience
                      </IonToggle>
                    )}
                  />
                </IonItem>
                <IonItem>
                  <Controller
                    control={control}
                    name="requireRegistrationBeforeAnsweringQuestion"
                    render={({ field: { onChange, value } }) => (
                      <IonToggle
                        checked={value}
                        onIonChange={(e) => onChange(e.detail.checked)}
                      >
                        Require registration before answering questions
                        (pooling, voting)
                      </IonToggle>
                    )}
                  />
                </IonItem>
              </IonList>

              <IonItemDivider sticky={true}>
                User Registration Fields
              </IonItemDivider>

              <IonItem>
                <IonSelect
                  labelPlacement="stacked"
                  {...register("userRegistrationFieldRequirements_firstName")}
                >
                  <div slot="label">First Name</div>
                  <IonSelectOption value="notUsed">Not Used</IonSelectOption>
                  <IonSelectOption value="optional">Optional</IonSelectOption>
                  <IonSelectOption value="required">Required</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonSelect
                  labelPlacement="stacked"
                  {...register("userRegistrationFieldRequirements_lastName")}
                >
                  <div slot="label">Last Name</div>
                  <IonSelectOption value="notUsed">Not Used</IonSelectOption>
                  <IonSelectOption value="optional">Optional</IonSelectOption>
                  <IonSelectOption value="required">Required</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonSelect
                  labelPlacement="stacked"
                  {...register(
                    "userRegistrationFieldRequirements_emailAddress"
                  )}
                >
                  <div slot="label">Email Address</div>
                  <IonSelectOption value="notUsed">Not Used</IonSelectOption>
                  <IonSelectOption value="optional">Optional</IonSelectOption>
                  <IonSelectOption value="required">Required</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonSelect
                  labelPlacement="stacked"
                  {...register("userRegistrationFieldRequirements_phoneNumber")}
                >
                  <div slot="label">Phone Number</div>
                  <IonSelectOption value="notUsed">Not Used</IonSelectOption>
                  <IonSelectOption value="optional">Optional</IonSelectOption>
                  <IonSelectOption value="required">Required</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonSelect
                  labelPlacement="stacked"
                  {...register("userRegistrationFieldRequirements_postcode")}
                >
                  <div slot="label">Postcode</div>
                  <IonSelectOption value="notUsed">Not Used</IonSelectOption>
                  <IonSelectOption value="optional">Optional</IonSelectOption>
                  <IonSelectOption value="required">Required</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonSelect
                  labelPlacement="stacked"
                  {...register("userRegistrationFieldRequirements_gender")}
                >
                  <div slot="label">Gender</div>
                  <IonSelectOption value="notUsed">Not Used</IonSelectOption>
                  <IonSelectOption value="optional">Optional</IonSelectOption>
                  <IonSelectOption value="required">Required</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonSelect
                  labelPlacement="stacked"
                  {...register("userRegistrationFieldRequirements_dateOfBirth")}
                >
                  <div slot="label">Date of Birth</div>
                  <IonSelectOption value="notUsed">Not Used</IonSelectOption>
                  <IonSelectOption value="optional">Optional</IonSelectOption>
                  <IonSelectOption value="required">Required</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonSelect
                  labelPlacement="stacked"
                  {...register(
                    "userRegistrationFieldRequirements_optInMarketing"
                  )}
                >
                  <div slot="label">Opt-in Marketing</div>
                  <IonSelectOption value="notUsed">Not Used</IonSelectOption>
                  <IonSelectOption value="optional">Optional</IonSelectOption>
                  <IonSelectOption value="required">Required</IonSelectOption>
                </IonSelect>
              </IonItem>

              <div className="ion-padding">
                <IonButton type="submit" color="primary" disabled={isSaving}>
                  Save
                  {isSaving && (
                    <span className="ml-2">
                      <Spinner
                        color={"success"}
                        size="sm"
                        aria-label="Saving..."
                      />
                    </span>
                  )}
                </IonButton>
                <IonButton
                  color="danger"
                  fill="clear"
                  onClick={() => setIsOpen(false)}
                  disabled={isSaving}
                >
                  Cancel
                </IonButton>
              </div>
            </form>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default EditEventButtonAndModal;
