/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button, Spinner, Table } from "flowbite-react";
import { useEffect, type FC, useState } from "react";

import { combineLatest } from "rxjs";
import GroupService from "../../services/group.service";
import { useBrandContext } from "../../context/brand.context";
import { auth } from "../../firebase/firebase";
import { Group } from "../../models/group";
import EventService from "../../services/event.service";
import { Brand } from "../../models/brand";
import { Accordion } from "flowbite-react";
import { Event } from "../../models/event";
import { HiChevronDoubleRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import {
  IonBackButton,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ErrorAlert from "../../components/ErrorAlert";
import { addOutline } from "ionicons/icons";
import EditEventButtonAndModal from "../../components/modals/edit-event-button-and-modal";
import EditGroupButtonAndModal from "../../components/modals/edit-group-button-and-modal";
import DeleteGroupButtonAndModal from "../../components/modals/delete-group-button-and-modal";
import DeleteEventButtonAndModal from "../../components/modals/delete-event-button-and-modal";
import Breadcrumbs from "../../components/Breadcrumbs";
import SpinnerCentered from "../../components/SpinnerCentered";

const GroupsPage: FC = function () {
  const pageTitle = "Event Groups";
  const brand = useBrandContext();

  // Error Message States
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [groups, setGroups] = useState<Group[]>([]);

  useEffect(() => {
    const subscription = combineLatest([
      GroupService.getAllByBrand(String(auth.tenantId), String(brand.id)),
    ]).subscribe({
      next: ([groups]) => {
        setGroups(groups);
        setIsLoading(false);
      },
      error: (err) => {
        setIsLoading(false);
        setErrorData(err);
        setErrorModalIsOpen(true);
        throw err;
      },
      complete: () => console.info("complete"),
    });

    return () => subscription.unsubscribe();
  }, [brand]);

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
          <IonButtons slot="end">
            <EditGroupButtonAndModal
              groupId={undefined}
              setErrorData={setErrorData}
              setErrorModalIsOpen={setErrorModalIsOpen}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {isLoading ? (
          <SpinnerCentered />
        ) : (
          <>
            <Breadcrumbs data={[{ name: "Event Groups" }]}></Breadcrumbs>
            <p className="ion-padding">
              Experiences are organized by groups of events. Expand a group to
              view the events. Select an event to view its experiences.
            </p>

            {groups.length === 0 && (
              <div className="m-4">
                <p>
                  Create your first Group by clicking the "New Group" button.
                </p>
              </div>
            )}
            <div className="m-4">
              <Accordion collapseAll>
                {groups.map((group) => (
                  <Accordion.Panel key={group.id}>
                    <Accordion.Title>{group.groupName}</Accordion.Title>
                    <Accordion.Content>
                      <div className="sm:flex w-full mb-4">
                        <h2 className="text-lg font-semibold text-gray-900 dark:text-white sm:text-lg inline-block">
                          Events
                        </h2>
                        <div className="inline-block ml-4">
                          <EditEventButtonAndModal
                            eventId={undefined}
                            group={group}
                            setErrorData={setErrorData}
                            setErrorModalIsOpen={setErrorModalIsOpen}
                          />
                        </div>
                        <div className="inline-block ml-4">
                          <h2>|</h2>
                        </div>
                        <div className="inline-block ml-4">
                          <EditGroupButtonAndModal
                            groupId={group.id}
                            setErrorData={setErrorData}
                            setErrorModalIsOpen={setErrorModalIsOpen}
                          />
                        </div>
                        <div className="inline-block ml-4">
                          <DeleteGroupButtonAndModal
                            groupId={group.id}
                            setErrorData={setErrorData}
                            setErrorModalIsOpen={setErrorModalIsOpen}
                          />
                        </div>
                      </div>

                      <EventsTable
                        group={group}
                        brand={brand}
                        setErrorData={setErrorData}
                        setErrorModalIsOpen={setErrorModalIsOpen}
                      />
                    </Accordion.Content>
                  </Accordion.Panel>
                ))}
              </Accordion>
            </div>
          </>
        )}

        <ErrorAlert
          errorData={errorData}
          isOpen={errorModalIsOpen}
          setIsOpen={setErrorModalIsOpen}
        />
      </IonContent>
    </IonPage>
  );
};

const EventsTable: FC<{
  group: Group;
  brand: Brand;
  setErrorData: React.Dispatch<React.SetStateAction<Error | undefined>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ group, brand, setErrorData, setErrorModalIsOpen }) => {
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    const subscription = combineLatest([
      EventService.getAllByGroup(
        String(auth.tenantId),
        String(brand.id),
        String(group.id)
      ),
    ]).subscribe({
      next: ([events]) => {
        setEvents(events);
      },
      error: (err) => {
        setErrorData(err);
        setErrorModalIsOpen(true);
        throw err;
      },
      complete: () => console.info("complete"),
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      <div className="overflow-x-auto">
        <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
          <Table.Head className="bg-gray-100 dark:bg-gray-700">
            <Table.HeadCell>Event Name</Table.HeadCell>
            <Table.HeadCell>Stores</Table.HeadCell>
            <Table.HeadCell>User Registration</Table.HeadCell>
            <Table.HeadCell>Experiences</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
            {events.map((event) => (
              <Table.Row
                className="hover:bg-gray-100 dark:hover:bg-gray-700"
                key={event.id}
              >
                <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                  <div className="text-base underline font-semibold text-gray-900 dark:text-white">
                    <Link to={`/brand/${brand.id}/groups/event/${event.id}`}>
                      {event.eventName}
                    </Link>
                  </div>
                  <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {event.eventDate}
                  </div>
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  {event.storeIds?.length || 0}
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  {event.requireRegistrationBeforeExperience
                    ? "Before Experience "
                    : ""}
                  {event.requireRegistrationBeforeExperience
                    ? "Before Question "
                    : ""}
                  {!event.requireRegistrationBeforeExperience &&
                  !event.requireRegistrationBeforeAnsweringQuestion
                    ? "None"
                    : ""}
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  TBD
                </Table.Cell>
                <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                  <div className="inline-block m-2">
                    <IonButton
                      routerDirection="forward"
                      routerLink={`/brand/${brand.id}/groups/event/${event.id}`}
                      size="small"
                      color="primary"
                    >
                      Manage
                      <HiChevronDoubleRight className="ml-1 h-3 w-3" />
                    </IonButton>
                  </div>
                  <div className="inline-block m-2">
                    <EditEventButtonAndModal
                      buttonCopy={"Edit"}
                      eventId={event.id}
                      group={group}
                      setErrorData={setErrorData}
                      setErrorModalIsOpen={setErrorModalIsOpen}
                    />
                  </div>
                  <div className="inline-block m-2">
                    <DeleteEventButtonAndModal
                      buttonCopy={"Delete"}
                      eventId={event.id}
                      setErrorData={setErrorData}
                      setErrorModalIsOpen={setErrorModalIsOpen}
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      {events.length === 0 && (
        <div className="m-4">
          <p>Create your first Event by clicking the "New Event" button.</p>
        </div>
      )}
    </>
  );
};

export default GroupsPage;
