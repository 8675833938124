import {
  DocumentData,
  Query,
  QueryConstraint,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { ExperienceQuestion } from "../models/experience-question";

function generateId() {
  return doc(collection(db, "experienceQuestions")).id;
}

function getOne(id: string): Observable<ExperienceQuestion> {
  if (!id) return of(undefined);
  const docRef = doc(db, `experienceQuestions/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAllByExperience(
  tenantId: string,
  brandId: string,
  experienceId: string
): Observable<ExperienceQuestion[]> {
  const collectionRef = collection(db, "experienceQuestions");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("experienceId", "==", experienceId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      //sort by order
      collection.sort(
        (a, b) => (a.order ? a.order : 0) - (b.order ? b.order : 0)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

async function saveOne(
  experienceQuestion: Partial<ExperienceQuestion>,
  isNew: boolean
): Promise<any> {
  try {
    if (isNew) {
      experienceQuestion.createdDate = serverTimestamp();
      experienceQuestion.createdByUserAccountId = auth.currentUser.uid;
    } else {
      experienceQuestion.updatedDate = serverTimestamp();
      experienceQuestion.updatedByUserAccountId = auth.currentUser.uid;
    }

    if (experienceQuestion.deleted == undefined)
      experienceQuestion.deleted = false;

    experienceQuestion =
      DataTransformationService.convertDatesToFirestoreTimestamp(
        experienceQuestion
      );

    let docRef = doc(db, `experienceQuestions/${experienceQuestion.id}`);
    await setDoc(docRef, experienceQuestion, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(experienceQuestionId: string): Promise<any> {
  try {
    let docRef = doc(db, `experienceQuestions/${experienceQuestionId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

const ExperienceQuestionService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllByExperience,
};

export default ExperienceQuestionService;
