/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, type FC, useState } from "react";
import ErrorModal from "../../components/modals/error-modal";

import { useBrandContext } from "../../context/brand.context";
import { Subscription, combineLatest } from "rxjs";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import SpinnerCentered from "../../components/SpinnerCentered";
import Breadcrumbs from "../../components/Breadcrumbs";
import { auth } from "../../firebase/firebase";
import UserAccountService from "../../services/user-account.service";
import { UserAccount } from "../../models/user-account";

const MyAccountPage: FC = function () {
  const brand = useBrandContext();
  const pageTitle = "My Account";

  // Error Message States
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [userAccount, setUserAccount] = useState<UserAccount>();

  useEffect(() => {
    const subscription: Subscription = combineLatest([
      UserAccountService.getOne(auth.currentUser?.uid),
    ]).subscribe({
      next: ([userAccount]) => {
        setUserAccount(userAccount);
        setIsLoading(false);
      },
      error: (err) => {
        setIsLoading(false);
        setErrorData(err);
        setErrorModalIsOpen(true);
        throw err;
      },
      complete: () => console.info("complete"),
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
          <IonButtons slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {isLoading ? (
          <SpinnerCentered />
        ) : (
          <>
            <Breadcrumbs data={[{ name: pageTitle }]}></Breadcrumbs>

            <p className="ion-padding">Coming soon.</p>
          </>
        )}

        <ErrorModal
          errorData={errorData}
          errorModalIsOpen={errorModalIsOpen}
          setErrorModalIsOpen={setErrorModalIsOpen}
        />
      </IonContent>
    </IonPage>
  );
};

export default MyAccountPage;
