import { auth, functionsUrl } from "../firebase/firebase";
import axios from "axios";
import { Timeframe } from "../models/timeframe";
import { DateTime } from "luxon";

async function getDailyVisitsForBrand(
  timeframe: Timeframe,
  brandId?: string
): Promise<any> {
  try {
    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //get signed url from firebase function
    const apiResponse = await client.get(
      `reporting/visits/daily/${timeframe.startTime}/${timeframe.endTime}/${encodeURIComponent(DateTime.fromISO(timeframe.endTime).zoneName)}/brand/${brandId}`
    );

    const visits: any[] = apiResponse?.data;

    return visits;
  } catch (err) {
    throw err;
  }
}

async function getTotalVisitsForBrand(
  timeframe: Timeframe,
  brandId?: string
): Promise<any> {
  try {
    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //get signed url from firebase function
    const apiResponse = await client.get(
      `reporting/visits/total/${timeframe.startTime}/${timeframe.endTime}/brand/${brandId}`
    );

    const visits: any[] = apiResponse?.data;

    return visits;
  } catch (err) {
    throw err;
  }
}

async function getDailyUsersForBrand(
  timeframe: Timeframe,
  brandId?: string
): Promise<any> {
  try {
    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //get signed url from firebase function
    const apiResponse = await client.get(
      `reporting/users/daily/${timeframe.startTime}/${timeframe.endTime}/${encodeURIComponent(DateTime.fromISO(timeframe.endTime).zoneName)}/brand/${brandId}`
    );

    const visits: any[] = apiResponse?.data;

    return visits;
  } catch (err) {
    throw err;
  }
}

async function getTotalUsersForBrand(
  timeframe: Timeframe,
  brandId?: string
): Promise<any> {
  try {
    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //get signed url from firebase function
    const apiResponse = await client.get(
      `reporting/users/total/${timeframe.startTime}/${timeframe.endTime}/brand/${brandId}`
    );

    const visits: any[] = apiResponse?.data;

    return visits;
  } catch (err) {
    throw err;
  }
}

const ReportingService = {
  getDailyVisitsForBrand,
  getTotalVisitsForBrand,
  getDailyUsersForBrand,
  getTotalUsersForBrand,
};

export default ReportingService;
