import type { FC } from "react";
import { Spinner } from "flowbite-react";

/**
 * Gets a brandId from the URL parameter, set it in localStorage and fetches the brand to be used by Context API.
 */
const FileUploadInput: FC<{
  upload: (files: FileList | null) => void;
  isUploading: boolean;
}> = ({ upload, isUploading }) => {
  return (
    <>
      {isUploading ? (
        <Spinner color={"success"}></Spinner>
      ) : (
        <input
          type="file"
          className="text-sm text-gray-600 dark:text-gray-400 inline-block"
          onChange={(file) => {
            upload(file.target.files);
          }}
        />
      )}
    </>
  );
};

export default FileUploadInput;
