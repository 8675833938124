import {
  DocumentData,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { Group } from "../models/group";

function generateId() {
  return doc(collection(db, "groups")).id;
}

function getOne(groupId: string): Observable<Group> {
  const docRef = doc(db, `groups/${groupId}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAllByBrand(tenantId: string, brandId: string): Observable<Group[]> {
  const collectionRef = collection(db, "groups");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) =>
          (a.createdDate && a.createdDate instanceof Timestamp
            ? a.createdDate.seconds
            : 99999999999) -
          (b.createdDate && b.createdDate instanceof Timestamp
            ? b.createdDate.seconds
            : 99999999999)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

async function saveOne(group: Partial<Group>, isNew: boolean): Promise<any> {
  try {
    if (isNew) {
      group.createdDate = serverTimestamp();
      group.createdByUserAccountId = auth.currentUser?.uid;
    } else {
      group.updatedDate = serverTimestamp();
      group.updatedByUserAccountId = auth.currentUser?.uid;
    }

    if (group.deleted == undefined) group.deleted = false;

    group = DataTransformationService.convertDatesToFirestoreTimestamp(group);

    let docRef = doc(db, `groups/${group.id}`);
    await setDoc(docRef, group, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(groupId: string): Promise<any> {
  try {
    let docRef = doc(db, `groups/${groupId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser?.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

const GroupService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllByBrand,
};

export default GroupService;
