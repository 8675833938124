import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { useForm, useFieldArray } from "react-hook-form";
import { firstValueFrom } from "rxjs";
import ProductService from "../../services/product.service";
import { Spinner } from "flowbite-react";
import { Product } from "../../models/product";
import { auth } from "../../firebase/firebase";
import { HiPencilAlt, HiPlus, HiTrash } from "react-icons/hi";
import StoreService from "../../services/store.service";
import { Store } from "../../models/store";
import FileUploadInput from "../file-upload-input";
import ModelViewer from "../ModelViewer";
import { ProductVariant } from "../../models/product-variant";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import ProductVariantService from "../../services/product-variant.service";

const EditProductButtonAndModal: FC<{
  buttonCopy?: string | undefined;
  productId: string | undefined;
  store: Store;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ buttonCopy, productId, store, setErrorData, setErrorModalIsOpen }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [stores, setStores] = useState<Store[]>([]);

  const [productVariantsToDelete, setProductVariantsToDelete] = useState<
    string[]
  >([]);

  const brand = useBrandContext();

  const { register, handleSubmit, setValue, watch, control } = useForm({
    defaultValues: {
      id: null,
      tenantId: null,
      brandId: null,
      storeId: null,
      productName: null,
      productDescription: null,
      productType: null,
      productImageUrl: null,
      productModelUrl: null,
      productVariants: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "productVariants",
    control,
  });

  useEffect(() => {
    // Get the product data if the modal is open
    async function getData() {
      if (isOpen) {
        setIsLoading(true);
        const stores = await firstValueFrom(
          StoreService.getAllByBrand(auth.tenantId, brand.id)
        );
        setStores(stores);

        if (productId) {
          const product = await firstValueFrom(
            ProductService.getOne(productId)
          );
          const productVariants = await firstValueFrom(
            ProductVariantService.getAllByProduct(
              auth.tenantId,
              brand.id,
              productId
            )
          );
          updateFormValues(product, productVariants);
        } else {
          const newProduct: Partial<Product> = {
            id: ProductService.generateId(),
            tenantId: auth.tenantId,
            brandId: brand.id,
            storeId: store.id,
          };
          updateFormValues(newProduct, []);
        }

        setIsLoading(false);
      }
      return;
    }

    getData().catch((err) => {
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    });
  }, [isOpen, productId]);

  // Update the form values when the product is loaded
  function updateFormValues(
    product: Product,
    productVariants: ProductVariant[]
  ) {
    setValue("id", product.id);
    setValue("tenantId", product.tenantId);
    setValue("brandId", product.brandId);
    setValue("storeId", product.storeId);
    setValue("productName", product.productName || null);
    setValue("productDescription", product.productDescription || null);
    setValue("productType", product.productType || null);
    setValue("productImageUrl", product.productImageUrl || null);
    setValue("productModelUrl", product.productModelUrl || null);
    setValue("productVariants", productVariants || []);
  }

  async function onSubmit(formData: any) {
    try {
      setIsSaving(true);

      // get the product variants and remove from form data
      const productVariants = formData.productVariants;
      delete formData.productVariants;

      // Save the product
      await ProductService.saveOne(formData, productId ? false : true);

      // Save each product variant
      for (const productVariant of productVariants) {
        const isNew = !productVariant.id;
        if (!productVariant.id) {
          productVariant.id = ProductVariantService.generateId();
        }
        await ProductVariantService.saveOne(productVariant, isNew);
      }

      // Delete the product variants that were removed
      for (const productVariantId of productVariantsToDelete) {
        await ProductVariantService.deleteOne(productVariantId);
      }

      setIsSaving(false);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  async function uploadImage(files: FileList | null) {
    try {
      if (!files || files.length == 0) {
        setValue("productImageUrl", null);
        return;
      }

      setIsSaving(true);
      const file = files[0];
      const fileUrl = await ProductService.uploadProductFile(
        brand.id,
        watch("id"),
        file
      );
      setValue("productImageUrl", fileUrl);
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  async function uploadModel(files: FileList | null) {
    try {
      if (!files || files.length == 0) {
        setValue("productModelUrl", null);
        return;
      }

      setIsSaving(true);
      const file = files[0];
      const fileUrl = await ProductService.uploadProductFile(
        brand.id,
        watch("id"),
        file
      );
      setValue("productModelUrl", fileUrl);
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  function removeProductVariant(index: number, productVariantId?: string) {
    remove(index);
    if (productVariantId) {
      setProductVariantsToDelete(
        productVariantsToDelete.concat(productVariantId)
      );
    }
  }

  function appendBlankProductVariant() {
    const blankProductVariant: ProductVariant = {
      id: undefined,
      tenantId: watch("tenantId"),
      brandId: watch("brandId"),
      productId: watch("id"),
      productVariantDescription: null,
      productVariantPrice: 0,
      productVariantUrlInStore: null,
      productVariantIdInStore: null,
    };
    append(blankProductVariant);
  }

  return (
    <>
      {productId ? (
        <IonButton
          size="small"
          fill="solid"
          color="warning"
          onClick={() => setIsOpen(true)}
        >
          <HiPencilAlt className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "Edit Product"}
        </IonButton>
      ) : (
        <IonButton
          size="small"
          fill="solid"
          color="success"
          onClick={() => setIsOpen(true)}
        >
          <HiPlus className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "New Product"}
        </IonButton>
      )}

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {productId ? (
                <strong>Edit Product</strong>
              ) : (
                <strong>New Product</strong>
              )}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)} color="primary">
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {isLoading ? (
            <Spinner color="success" />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList>
                <IonItem>
                  <IonInput
                    {...register("productName")}
                    labelPlacement="floating"
                  >
                    <div slot="label">
                      Product name <IonText color="danger">(Required)</IonText>
                    </div>
                  </IonInput>
                </IonItem>
                <IonItem>
                  <IonTextarea
                    rows={4}
                    {...register("productDescription")}
                    labelPlacement="floating"
                  >
                    <div slot="label">Product Description</div>
                  </IonTextarea>
                </IonItem>

                <IonItem>
                  <IonSelect
                    labelPlacement="stacked"
                    {...register("productType")}
                  >
                    <div slot="label">Product Type</div>
                    <IonSelectOption value="physicalItem">
                      Physical Item
                    </IonSelectOption>
                    <IonSelectOption value="application">
                      Application
                    </IonSelectOption>
                    <IonSelectOption value="subscription">
                      Subscription
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
                <div className="ion-padding">
                  <IonLabel className="block">Product Image</IonLabel>
                  <img src={watch("productImageUrl")} alt="" className="mb-4" />
                  <FileUploadInput
                    upload={uploadImage}
                    isUploading={isSaving}
                  />
                  {watch("productImageUrl") && (
                    <IonButton
                      size="small"
                      className="inline-block"
                      color="danger"
                      onClick={() => {
                        uploadImage(null);
                      }}
                    >
                      <HiTrash className="mr-1 h-3 w-3" />
                      Remove
                    </IonButton>
                  )}
                </div>
                <div className="ion-padding">
                  <IonLabel className="block">Product 3D Model</IonLabel>
                  {watch("productModelUrl") && (
                    <ModelViewer modelFileUrl={watch("productModelUrl")} />
                  )}
                  <FileUploadInput
                    upload={uploadModel}
                    isUploading={isSaving}
                  />
                  {watch("productModelUrl") && (
                    <IonButton
                      size="small"
                      className="inline-block"
                      color="danger"
                      onClick={() => {
                        uploadModel(null);
                      }}
                    >
                      <HiTrash className="mr-1 h-3 w-3" />
                      Remove
                    </IonButton>
                  )}
                </div>
                {fields.map((field, index) => {
                  return (
                    <div key={field.id}>
                      <IonItemDivider>
                        Product Variant {index + 1}
                        <IonButton
                          size="small"
                          fill="clear"
                          className="inline-block"
                          color="danger"
                          slot="end"
                          onClick={() => {
                            removeProductVariant(
                              index,
                              watch(`productVariants.${index}.id`)
                            );
                          }}
                        >
                          <IonIcon slot="icon-only" icon={closeOutline} />
                        </IonButton>
                      </IonItemDivider>
                      <IonItem>
                        <IonInput
                          {...register(
                            `productVariants.${index}.productVariantDescription`
                          )}
                          labelPlacement="floating"
                        >
                          <div slot="label">Variant description</div>
                        </IonInput>
                      </IonItem>
                      <IonItem>
                        <IonInput
                          {...register(
                            `productVariants.${index}.productVariantPrice`
                          )}
                          labelPlacement="floating"
                          type="text"
                        >
                          <div slot="label">Variant Price</div>
                        </IonInput>
                      </IonItem>
                      <IonItem>
                        <IonInput
                          {...register(
                            `productVariants.${index}.productVariantUrlInStore`
                          )}
                          labelPlacement="floating"
                          type="url"
                        >
                          <div slot="label">Variant URL in Store</div>
                        </IonInput>
                      </IonItem>
                      <IonItem>
                        <IonInput
                          {...register(
                            `productVariants.${index}.productVariantIdInStore`
                          )}
                          labelPlacement="floating"
                          type="text"
                        >
                          <div slot="label">Variant ID in Store</div>
                        </IonInput>
                      </IonItem>
                    </div>
                  );
                })}
                <div className="ion-padding text-center">
                  <IonButton
                    size="small"
                    className="inline-block"
                    color="success"
                    onClick={() => {
                      appendBlankProductVariant();
                    }}
                  >
                    <HiPlus className="mr-1 h-3 w-3" />
                    Add Variant
                  </IonButton>
                </div>
              </IonList>

              <div className="ion-padding">
                <IonButton type="submit" color="primary" disabled={isSaving}>
                  Save
                  {isSaving && (
                    <span className="ml-2">
                      <Spinner
                        color={"success"}
                        size="sm"
                        aria-label="Saving..."
                      />
                    </span>
                  )}
                </IonButton>
                <IonButton
                  color="danger"
                  fill="clear"
                  onClick={() => setIsOpen(false)}
                  disabled={isSaving}
                >
                  Cancel
                </IonButton>
              </div>
            </form>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default EditProductButtonAndModal;
