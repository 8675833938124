import {
  DocumentData,
  Query,
  QueryConstraint,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { UserAccountTenantPermission } from "../models/user-account-tenant-permission";

function generateId(userAccountId: string, tenantId: string) {
  return `${userAccountId}_${tenantId}`;
}

function getOne(id: string): Observable<UserAccountTenantPermission> {
  const docRef = doc(db, `userAccountTenantPermissions/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

async function saveOne(
  userAccountTenantPermission: Partial<UserAccountTenantPermission>,
  isNew: boolean
): Promise<void> {
  try {
    if (!userAccountTenantPermission.id)
      throw new Error("Missing Permission ID.");

    if (isNew) {
      userAccountTenantPermission.createdDate = serverTimestamp();
      userAccountTenantPermission.createdByUserAccountId =
        auth.currentUser?.uid;
    } else {
      userAccountTenantPermission.updatedDate = serverTimestamp();
      userAccountTenantPermission.updatedByUserAccountId =
        auth.currentUser?.uid;
    }

    if (userAccountTenantPermission.deleted == undefined)
      userAccountTenantPermission.deleted = false;

    let docRef = doc(
      db,
      `userAccountTenantPermissions/${userAccountTenantPermission.id}`
    );
    await setDoc(docRef, userAccountTenantPermission, { merge: false });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(id: string): Promise<any> {
  try {
    let docRef = doc(db, `userAccountTenantPermissions/${id}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser?.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
    return;
  } catch (err) {
    throw err;
  }
}

function getAllForTenant(
  tenantId: string
): Observable<UserAccountTenantPermission[]> {
  const collectionRef = collection(db, "userAccountTenantPermissions");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) =>
      collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      )
    )
  );
}

function getAllForUserAccount(
  tenantId: string,
  userAccountId: string
): Observable<UserAccountTenantPermission[]> {
  const collectionRef = collection(db, "userAccountTenantPermissions");

  const queryConstraint: QueryConstraint[] = [
    where("userAccountId", "==", userAccountId),
    where("tenantId", "==", tenantId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) =>
      collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      )
    )
  );
}

function getAllForUserAccountIncludingDeleted(
  tenantId: string,
  userAccountId: string
): Observable<UserAccountTenantPermission[]> {
  const collectionRef = collection(db, "userAccountTenantPermissions");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("userAccountId", "==", userAccountId),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) =>
      collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      )
    )
  );
}

const UserAccountTenantPermissionService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllForUserAccount,
  getAllForTenant,
  getAllForUserAccountIncludingDeleted,
};

export default UserAccountTenantPermissionService;
