/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breadcrumb,
  Button,
  Label,
  Spinner,
  Table,
  TextInput,
} from "flowbite-react";
import { useEffect, type FC, useState } from "react";
import ErrorModal from "../../components/modals/error-modal";

import { useBrandContext } from "../../context/brand.context";
import { combineLatest } from "rxjs";
import QuestionService from "../../services/question.service";
import { auth } from "../../firebase/firebase";
import { HiChevronDoubleRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import EditQuestionButtonAndModal from "../../components/modals/edit-question-button-and-modal";
import DeleteQuestionButtonAndModal from "../../components/modals/delete-question-button-and-modal";
import { Question } from "../../models/question";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import SpinnerCentered from "../../components/SpinnerCentered";
import Breadcrumbs from "../../components/Breadcrumbs";

const QuestionsPage: FC = function () {
  const brand = useBrandContext();
  const pageTitle = "Questions";

  // Error Message States
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [questions, setQuestions] = useState<Question[]>([]);
  const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);

  useEffect(() => {
    const subscription = combineLatest([
      QuestionService.getAllByBrand(auth.tenantId, brand.id),
    ]).subscribe({
      next: ([sets]) => {
        setQuestions(sets);
        setFilteredQuestions(sets);
        setIsLoading(false);
      },
      error: (err) => {
        setIsLoading(false);
        setErrorData(err);
        setErrorModalIsOpen(true);
        throw err;
      },
      complete: () => console.info("complete"),
    });

    return () => subscription.unsubscribe();
  }, []);

  function handleSearch(searchVal: any) {
    console.log(searchVal);
    if (searchVal === "") {
      setFilteredQuestions(questions);
      return;
    }

    const filterBySearch = questions.filter((question) => {
      if (
        JSON.stringify(question).toLowerCase().includes(searchVal.toLowerCase())
      ) {
        return question;
      } else {
        return undefined;
      }
    });

    setFilteredQuestions(filterBySearch);
  }

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
          <IonButtons slot="end">
            <EditQuestionButtonAndModal
              buttonCopy={"New Question"}
              questionId={undefined}
              setErrorData={setErrorData}
              setErrorModalIsOpen={setErrorModalIsOpen}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {isLoading ? (
          <SpinnerCentered />
        ) : (
          <>
            <Breadcrumbs data={[{ name: pageTitle }]}></Breadcrumbs>

            <IonSearchbar
              id="search"
              name="search"
              placeholder="Search"
              debounce={500}
              onIonInput={(ev) => handleSearch(ev.detail.value)}
            ></IonSearchbar>

            <div className="m-4 overflow-x-auto">
              <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                <Table.Head className="bg-gray-100 dark:bg-gray-700">
                  <Table.HeadCell>Photo</Table.HeadCell>
                  <Table.HeadCell>Question Text</Table.HeadCell>
                  <Table.HeadCell></Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
                  {filteredQuestions.map((question) => (
                    <Table.Row
                      className="hover:bg-gray-100 dark:hover:bg-gray-700"
                      key={question.id}
                    >
                      <Table.Cell>
                        {question.questionImageUrl && (
                          <img
                            src={question.questionImageUrl}
                            className="w-32 aspect-square"
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell className="p-4 text-sm font-normal text-gray-500 dark:text-gray-400">
                        <div className="text-base underline font-semibold text-gray-900 dark:text-white">
                          <Link
                            to={`/brand/${brand.id}/questions/${question.id}`}
                          >
                            {question.questionText}
                          </Link>
                        </div>
                      </Table.Cell>
                      <Table.Cell className="p-4 text-base font-medium text-gray-900 dark:text-white">
                        <div className="inline-block m-2">
                          <IonButton
                            routerDirection="forward"
                            routerLink={`/brand/${brand.id}/questions/${question.id}`}
                            size="small"
                            color="primary"
                          >
                            Manage
                            <HiChevronDoubleRight className="ml-1 h-3 w-3" />
                          </IonButton>
                        </div>
                        <div className="inline-block m-2">
                          <EditQuestionButtonAndModal
                            buttonCopy={"Edit"}
                            questionId={question.id}
                            setErrorData={setErrorData}
                            setErrorModalIsOpen={setErrorModalIsOpen}
                          />
                        </div>
                        <div className="inline-block m-2">
                          <DeleteQuestionButtonAndModal
                            buttonCopy={"Delete"}
                            questionId={question.id}
                            setErrorData={setErrorData}
                            setErrorModalIsOpen={setErrorModalIsOpen}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>

            {questions.length === 0 && (
              <div className="m-4">
                <p>
                  Create your first question by clicking the "New Question"
                  button.
                </p>
              </div>
            )}
          </>
        )}

        <ErrorModal
          errorData={errorData}
          errorModalIsOpen={errorModalIsOpen}
          setErrorModalIsOpen={setErrorModalIsOpen}
        />
      </IonContent>
    </IonPage>
  );
};

export default QuestionsPage;
