import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { Controller, useForm } from "react-hook-form";
import { firstValueFrom } from "rxjs";
import StoreService from "../../services/store.service";
import { Spinner } from "flowbite-react";
import { Store } from "../../models/store";
import { auth } from "../../firebase/firebase";
import { HiPencilAlt, HiPlus, HiTrash } from "react-icons/hi";
import FileUploadInput from "../file-upload-input";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

const EditStoreModal: FC<{
  buttonCopy?: string | undefined;
  storeId: string | undefined;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ buttonCopy, storeId, setErrorData, setErrorModalIsOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { register, handleSubmit, setValue, watch, control } = useForm({
    defaultValues: {
      id: null,
      tenantId: null,
      brandId: null,
      storeName: "",
      storeType: null,
      storeUrl: null,
      storeWebsiteSupportsIFrameEmbed: false,
      deviceSpecificUrls: null,
      storeCurrency: null,
      supportsExperienceShoppingCart: false,
      supportsExperienceShoppingList: false,
      storeImageUrl: null,
    },
  });
  const brand = useBrandContext();

  useEffect(() => {
    if (isOpen) {
      if (storeId) {
        setIsLoading(true);

        firstValueFrom(StoreService.getOne(storeId))
          .then((store) => {
            setFormValue(store);
            setIsLoading(false);
          })
          .catch((err) => {
            setErrorData(err);
            setErrorModalIsOpen(true);
            setIsLoading(false);
            throw err;
          });
      } else {
        setIsLoading(true);
        setFormValue({
          id: null,
          tenantId: auth.tenantId,
          brandId: brand?.id,
          storeName: "",
          storeType: null,
          storeUrl: null,
          storeWebsiteSupportsIFrameEmbed: false,
          deviceSpecificUrls: null,
          storeCurrency: null,
          supportsExperienceShoppingCart: false,
          supportsExperienceShoppingList: false,
          storeImageUrl: null,
        } as Store);
        setIsLoading(false);
      }
    }
  }, [isOpen, storeId]);

  function setFormValue(store: Store) {
    setValue("id", store.id);
    setValue("tenantId", store.tenantId);
    setValue("brandId", store.brandId);
    setValue("storeName", store.storeName || null);
    setValue("storeType", store.storeType || null);
    setValue("storeUrl", store.storeUrl || null);
    setValue(
      "storeWebsiteSupportsIFrameEmbed",
      store.storeWebsiteSupportsIFrameEmbed || false
    );
    setValue("deviceSpecificUrls", store.deviceSpecificUrls || null);
    setValue("storeCurrency", store.storeCurrency || null);
    setValue(
      "supportsExperienceShoppingCart",
      store.supportsExperienceShoppingCart || false
    );
    setValue(
      "supportsExperienceShoppingList",
      store.supportsExperienceShoppingList || false
    );
    setValue("storeImageUrl", store.storeImageUrl || null);
  }

  async function uploadImage(files: FileList | null) {
    try {
      if (!files || files.length == 0) {
        setValue("storeImageUrl", null);
        return;
      }

      setIsSaving(true);
      const file = files[0];
      const fileUrl = await StoreService.uploadStoreFile(
        brand.id,
        watch("id"),
        file
      );
      setValue("storeImageUrl", fileUrl);
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  async function onSubmit(formData: any) {
    try {
      setIsSaving(true);

      const store: Store = formData;

      let isNew: boolean = false;

      if (storeId) {
        store.id = storeId;
      } else {
        store.id = StoreService.generateId();
        isNew = true;
      }

      await StoreService.saveOne(store, isNew);

      setIsSaving(false);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      {storeId ? (
        <IonButton
          size="small"
          fill="solid"
          color="warning"
          onClick={() => setIsOpen(true)}
        >
          <HiPencilAlt className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "Edit Store"}
        </IonButton>
      ) : (
        <IonButton
          size="small"
          fill="solid"
          color="success"
          onClick={() => setIsOpen(true)}
        >
          <HiPlus className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "New Store"}
        </IonButton>
      )}

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {storeId ? (
                <strong>Edit Store</strong>
              ) : (
                <strong>New Store</strong>
              )}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)} color="primary">
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {isLoading ? (
            <Spinner color="success" />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList>
                <IonItem>
                  <IonInput
                    {...register("storeName")}
                    labelPlacement="floating"
                  >
                    <div slot="label">
                      Store name <IonText color="danger">(Required)</IonText>
                    </div>
                  </IonInput>
                </IonItem>
                <IonItem>
                  <IonSelect
                    labelPlacement="stacked"
                    {...register("storeType")}
                  >
                    <div slot="label">Store Type</div>
                    <IonSelectOption value={"linkToProductPages"}>
                      Links to External Product Pages (e.g. ecommerce store)
                    </IonSelectOption>
                    <IonSelectOption value={"deviceSpecificLinkToProductPages"}>
                      Device Specific Links to External Product Pages (i.e.
                      links to app stores)
                    </IonSelectOption>
                    <IonSelectOption value={"shopify"}>
                      Shopify Store
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonInput
                    {...register("storeUrl")}
                    labelPlacement="floating"
                    type="url"
                  >
                    <div slot="label">Store URL</div>
                  </IonInput>
                </IonItem>
                <IonItem>
                  <Controller
                    control={control}
                    name="storeWebsiteSupportsIFrameEmbed"
                    render={({ field: { onChange, value } }) => (
                      <IonToggle
                        checked={value}
                        onIonChange={(e) => onChange(e.detail.checked)}
                      >
                        Store website supports iFrame embed
                        <p className="ion-text-wrap text-sm">
                          If the store's website does not support iFrame embeds,
                          then the store webpages will be opened in a new
                          window.
                        </p>
                      </IonToggle>
                    )}
                  />
                </IonItem>
                <IonItem>
                  <IonSelect
                    labelPlacement="stacked"
                    {...register("storeCurrency")}
                  >
                    <div slot="label">Store Currency</div>
                    <IonSelectOption value={"AUD"}>AUD</IonSelectOption>
                    <IonSelectOption value={"CAD"}>CAD</IonSelectOption>
                    <IonSelectOption value={"EUR"}>EUR</IonSelectOption>
                    <IonSelectOption value={"USD"}>USD</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <Controller
                    control={control}
                    name="supportsExperienceShoppingCart"
                    render={({ field: { onChange, value } }) => (
                      <IonToggle
                        checked={value}
                        onIonChange={(e) => onChange(e.detail.checked)}
                      >
                        Supports Experience Shopping Cart
                        <p className="ion-text-wrap text-sm">
                          User can add the store's products to a shopping cart
                          and make a purchase of them all at once.{" "}
                        </p>
                      </IonToggle>
                    )}
                  />
                </IonItem>
                <IonItem>
                  <Controller
                    control={control}
                    name="supportsExperienceShoppingList"
                    render={({ field: { onChange, value } }) => (
                      <IonToggle
                        checked={value}
                        onIonChange={(e) => onChange(e.detail.checked)}
                      >
                        Supports Experience Shopping List
                        <p className="ion-text-wrap text-sm">
                          User can add the store's products to a shopping list
                          and view or purchase one at a time on store's 2D
                          ecommerce website.
                        </p>
                      </IonToggle>
                    )}
                  />
                </IonItem>
                <div className="ion-padding">
                  <IonLabel>Store Image/Logo</IonLabel>
                  <img src={watch("storeImageUrl")} alt="" className="mb-4" />
                  <FileUploadInput
                    upload={uploadImage}
                    isUploading={isSaving}
                  />
                  {watch("storeImageUrl") && (
                    <IonButton
                      size="small"
                      className="inline-block"
                      color="danger"
                      onClick={() => {
                        uploadImage(null);
                      }}
                    >
                      <HiTrash className="mr-1 h-3 w-3" />
                      Remove
                    </IonButton>
                  )}
                </div>
              </IonList>
              <div className="ion-padding">
                <IonButton type="submit" color="primary" disabled={isSaving}>
                  Save
                  {isSaving && (
                    <span className="ml-2">
                      <Spinner
                        color={"success"}
                        size="sm"
                        aria-label="Saving..."
                      />
                    </span>
                  )}
                </IonButton>
                <IonButton
                  color="danger"
                  fill="clear"
                  onClick={() => setIsOpen(false)}
                  disabled={isSaving}
                >
                  Cancel
                </IonButton>
              </div>
            </form>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default EditStoreModal;
