/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useEffect, useState } from "react";
import { from } from "rxjs";
import BrandService from "../../services/brand.service";
import { Brand } from "../../models/brand";
import SpinnerCentered from "../../components/SpinnerCentered";
import ErrorAlert from "../../components/ErrorAlert";
import { useAuth } from "../../firebase/auth";
import {
  IonBadge,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { useUserAccountTenantPermissionsContext } from "../../context/userAccountTenantPermissions.context";
import { useUserAccountBrandPermissionsContext } from "../../context/userAccountBrandPermissions.context";
import EditBrandModal from "../../components/modals/edit-brand-button-and-modal";

const BrandsPage: FC = function () {
  const pageTitle = "Choose a Brand";
  const navigation = useIonRouter();

  // Error Message Handling
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [brands, setBrands] = useState<Brand[]>([]);

  const userAccountTenantPermissions = useUserAccountTenantPermissionsContext();
  const userAccountBrandPermissions = useUserAccountBrandPermissionsContext();

  const auth = useAuth();

  useEffect(() => {
    if (!auth.isLoading && auth.authUser) {
      if (userAccountTenantPermissions.length > 0) {
        // User is Tenant Admin
        const subscription = BrandService.getAllByTenant(
          auth.authUser.tenantId
        ).subscribe({
          next: (brands) => {
            setBrands(brands);
            setIsLoading(false);
          },
          error: (e) => {
            setIsLoading(false);
            setErrorData(e);
            setErrorModalIsOpen(true);
            throw e;
          },
          complete: () => console.info("complete"),
        });

        return () => subscription.unsubscribe();
      } else if (userAccountBrandPermissions.length > 0) {
        // User is Brand Admin
        const subscription = from(
          BrandService.getAllByUserAccountBrandPermission(
            auth.authUser.tenantId,
            auth.authUser.uid
          )
        ).subscribe({
          next: (brands) => {
            // Redirect automatically if only one brand and not a tenant admin
            if (brands.length === 1 && brands[0]) {
              navigation.push("/brand/" + brands[0].id + "/dashboard");
            } else {
              setBrands(brands);
            }
            setIsLoading(false);
          },
          error: (e) => {
            setIsLoading(false);
            setErrorData(e);
            setErrorModalIsOpen(true);
            throw e;
          },
          complete: () => console.info("complete"),
        });

        return () => subscription.unsubscribe();
      } else {
        setIsLoading(false);
        console.log("No Permissions");
        return;
      }
    }
  }, [auth, userAccountTenantPermissions, userAccountBrandPermissions]);

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
          <IonButtons slot="end">
            {userAccountTenantPermissions &&
              userAccountTenantPermissions.length > 0 &&
              userAccountTenantPermissions[0].admin && (
                <>
                  <EditBrandModal
                    brandId={undefined}
                    setErrorData={setErrorData}
                    setErrorModalIsOpen={setErrorModalIsOpen}
                  />
                </>
              )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {isLoading ? (
          <SpinnerCentered />
        ) : (
          <>
            <IonList>
              {brands.map(function (brand) {
                return (
                  <IonItem href={`brand/${brand.id}/dashboard`} key={brand.id}>
                    {brand.brandName}
                  </IonItem>
                );
              })}
            </IonList>
            {userAccountTenantPermissions?.length === 0 &&
              userAccountBrandPermissions?.length === 0 && (
                <p className="text-center font-bold m-4">
                  You have successfully logged in, but do not have access to any
                  brands. Request access from support.
                </p>
              )}
            {/* <IonButton
            onClick={() => fakeFunctionProduction()}
            className="btn btn-primary"
          >
            THROW ERROR
          </IonButton> */}
          </>
        )}
        <ErrorAlert
          errorData={errorData}
          isOpen={errorModalIsOpen}
          setIsOpen={setErrorModalIsOpen}
        />
      </IonContent>
    </IonPage>
  );
};

export default BrandsPage;
