import type { FC } from "react";
import { Spinner } from "flowbite-react";

/**
 * Gets a brandId from the URL parameter, set it in localStorage and fetches the brand to be used by Context API.
 */
const SpinnerCentered: FC = function () {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <Spinner color={"success"} size={"xl"} />
      </div>
    </div>
  );
};

export default SpinnerCentered;
