import {
  DocumentData,
  Query,
  QueryConstraint,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { Tenant } from "../models/tenant";
import { Experience } from "../models/experience";
import { TestAccess } from "../models/test-access";
import { Brand } from "../models/brand";

function getTestAccessUrl(
  tenant: Tenant,
  brand: Brand,
  experience: Experience,
  testAccess: TestAccess
): string {
  if (!brand.hostname && !tenant.hostnameForExperience)
    throw new Error("Experience hostname has not been configured.");
  if (!experience?.id) throw new Error("Experience does not have a valid ID.");
  if (!testAccess?.id) throw new Error("Test Access does not have a valid ID.");

  return `https://${brand.hostname || tenant.hostnameForExperience}/experience/${experience.id}/test/${testAccess.id}`;
}

function generateId() {
  return doc(collection(db, "testAccesses")).id;
}

function getOne(id: string): Observable<TestAccess> {
  if (!id) return of(undefined);
  const docRef = doc(db, `testAccesses/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAllByExperience(
  tenantId: string,
  brandId: string,
  experienceId: string
): Observable<TestAccess[]> {
  const collectionRef = collection(db, "testAccesses");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("experienceId", "==", experienceId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) => (a.order ? a.order : 0) - (b.order ? b.order : 0)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

async function saveOne(
  testAccess: Partial<TestAccess>,
  isNew: boolean
): Promise<any> {
  try {
    if (isNew) {
      testAccess.createdDate = serverTimestamp();
      testAccess.createdByUserAccountId = auth.currentUser.uid;
    } else {
      testAccess.updatedDate = serverTimestamp();
      testAccess.updatedByUserAccountId = auth.currentUser.uid;
    }

    if (testAccess.deleted == undefined) testAccess.deleted = false;

    testAccess =
      DataTransformationService.convertDatesToFirestoreTimestamp(testAccess);

    let docRef = doc(db, `testAccesses/${testAccess.id}`);
    await setDoc(docRef, testAccess, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(id: string): Promise<any> {
  try {
    let docRef = doc(db, `testAccesses/${id}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

const TestAccessService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllByExperience,
  getTestAccessUrl,
};

export default TestAccessService;
