import { IonBreadcrumb, IonBreadcrumbs } from "@ionic/react";
import { FC, useState } from "react";

const Breadcrumbs: FC<{
  data: { name: string; path?: string }[];
}> = ({ data }) => {
  const [maxBreadcrumbs, setMaxBreadcrumbs] = useState<number | undefined>(3);

  return (
    <>
      <IonBreadcrumbs
        maxItems={maxBreadcrumbs}
        onIonCollapsedClick={() => setMaxBreadcrumbs(undefined)}
      >
        {data.map((item, index) => {
          return (
            <div key={index}>
              {item.path ? (
                <IonBreadcrumb routerLink={item.path} routerDirection="back">
                  {item.name}
                </IonBreadcrumb>
              ) : (
                <IonBreadcrumb>{item.name}</IonBreadcrumb>
              )}
            </div>
          );
        })}
      </IonBreadcrumbs>
    </>
  );
};

export default Breadcrumbs;
