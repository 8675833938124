import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { set, useForm } from "react-hook-form";
import { firstValueFrom } from "rxjs";
import {
  Button,
  Label,
  Modal,
  Spinner,
  TextInput,
  Textarea,
} from "flowbite-react";
import { auth } from "../../firebase/firebase";
import { HiPencilAlt, HiPlus, HiTrash, HiUpload } from "react-icons/hi";
import SetService from "../../services/set.service";
import { Set } from "../../models/set";
import FileUploadInput from "../file-upload-input";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

const EditSetButtonAndModal: FC<{
  buttonCopy?: string | undefined;
  setId: string | undefined;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ buttonCopy, setId, setErrorData, setErrorModalIsOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      id: null,
      tenantId: null,
      brandId: null,
      setName: null,
      setDescription: null,
      setComponentName: null,
      setImageUrl: null,
      setModelUrl: null,
      maxProductCount: 0,
      maxVideoCount: 0,
      maxQuestionCount: 0,
    },
  });
  const brand = useBrandContext();

  useEffect(() => {
    if (isOpen) {
      if (setId) {
        firstValueFrom(SetService.getOne(setId))
          .then((set) => {
            updateFormValues(set);
          })
          .catch((err) => {
            setErrorData(err);
            setErrorModalIsOpen(true);
            throw err;
          });
      } else {
        const newSet: Partial<Set> = {
          id: SetService.generateId(),
          tenantId: auth.tenantId,
          brandId: brand.id,
        };
        updateFormValues(newSet);
      }
    }
  }, [isOpen, setId]);

  function updateFormValues(set: Partial<Set>) {
    setValue("id", set.id);
    setValue("tenantId", set.tenantId);
    setValue("brandId", set.brandId);
    setValue("setName", set.setName);
    setValue("setDescription", set.setDescription);
    setValue("setComponentName", set.setComponentName || null);
    setValue("setImageUrl", set.setImageUrl || null);
    setValue("setModelUrl", set.setModelUrl || null);
    setValue("maxProductCount", set.maxProductCount || 0);
    setValue("maxVideoCount", set.maxVideoCount || 0);
    setValue("maxQuestionCount", set.maxQuestionCount || 0);
  }

  async function uploadImage(files: FileList | null) {
    try {
      if (!files || files.length == 0) {
        setValue("setImageUrl", null);
        return;
      }

      setIsSaving(true);
      const file = files[0];
      const fileUrl = await SetService.uploadSetImage(
        brand.id,
        watch("id"),
        file
      );
      setValue("setImageUrl", fileUrl);
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  async function onSubmit(formData: any) {
    try {
      setIsSaving(true);

      await SetService.saveOne(formData, setId ? false : true);

      setIsSaving(false);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      {setId ? (
        <IonButton size="small" color="warning" onClick={() => setIsOpen(true)}>
          <HiPencilAlt className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "Edit Set"}
        </IonButton>
      ) : (
        <IonButton size="small" color="success" onClick={() => setIsOpen(true)}>
          <HiPlus className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "New Set"}
        </IonButton>
      )}

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {setId ? <strong>Edit Set</strong> : <strong>New Set</strong>}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)} color="primary">
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList>
              <IonItem>
                <IonInput {...register("setName")} labelPlacement="floating">
                  <div slot="label">
                    Set name <IonText color="danger">(Required)</IonText>
                  </div>
                </IonInput>
              </IonItem>
              <IonItem>
                <IonTextarea
                  rows={4}
                  {...register("setDescription")}
                  labelPlacement="floating"
                >
                  <div slot="label">Set description</div>
                </IonTextarea>
              </IonItem>
              <IonItem>
                <IonInput
                  {...register("maxProductCount")}
                  labelPlacement="floating"
                  type="number"
                >
                  <div slot="label">
                    Maximum Product Count{" "}
                    <IonText color="danger">(Required)</IonText>
                  </div>
                </IonInput>
              </IonItem>
              <IonItem>
                <IonInput
                  {...register("maxVideoCount")}
                  labelPlacement="floating"
                  type="number"
                >
                  <div slot="label">
                    Maximum Video Count{" "}
                    <IonText color="danger">(Required)</IonText>
                  </div>
                </IonInput>
              </IonItem>
              <IonItem>
                <IonInput
                  {...register("maxQuestionCount")}
                  labelPlacement="floating"
                  type="number"
                >
                  <div slot="label">
                    Maximum Question Count{" "}
                    <IonText color="danger">(Required)</IonText>
                  </div>
                </IonInput>

                <Label className="block">Set Image</Label>
                {watch("setImageUrl") && (
                  <img src={watch("setImageUrl")} alt="" className="mb-4" />
                )}
                <FileUploadInput upload={uploadImage} isUploading={isSaving} />
                {watch("setImageUrl") && (
                  <IonButton
                    size="small"
                    className="inline-block"
                    color="danger"
                    onClick={() => {
                      uploadImage(null);
                    }}
                  >
                    <HiTrash className="mr-1 h-3 w-3" />
                    Remove
                  </IonButton>
                )}
              </IonItem>

              <IonItem>
                <IonInput
                  {...register("setComponentName")}
                  labelPlacement="floating"
                >
                  <div slot="label">
                    Set Component Name [WARNING: TO BE EDITED BY TECHNICAL STAFF
                    ONLY]
                  </div>
                </IonInput>
              </IonItem>
            </IonList>

            <div className="ion-padding">
              <IonButton type="submit" color="primary" disabled={isSaving}>
                Save
                {isSaving && (
                  <span className="ml-2">
                    <Spinner
                      color={"success"}
                      size="sm"
                      aria-label="Saving..."
                    />
                  </span>
                )}
              </IonButton>
              <IonButton
                color="danger"
                fill="clear"
                onClick={() => setIsOpen(false)}
                disabled={isSaving}
              >
                Cancel
              </IonButton>
            </div>
          </form>
        </IonContent>
      </IonModal>
    </>
  );
};

export default EditSetButtonAndModal;
