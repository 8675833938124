import { UserCredential, UserInfo } from "firebase/auth";
import { UserAccount } from "../models/user-account";
import {
  DocumentData,
  Query,
  QueryConstraint,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { Observable, first, map } from "rxjs";
import { collectionData, docData } from "rxfire/firestore";
import { auth, db, functionsUrl } from "../firebase/firebase";
import DataTransformationService from "./data-transformation.service";
import axios from "axios";

/**
 * Save User data, and handle different data provide by either web social login or native social login (userCredential vs firebaseUser)
 * @param userCredential
 * @param firebaseUser
 * @param displayName
 * @param firstName
 * @param lastName
 */
async function saveOneWithCredential(
  userCredential: UserCredential,
  firstName?: string,
  lastName?: string
): Promise<void> {
  try {
    const providerData: UserInfo = userCredential.user.providerData[0];

    //create user profile object
    const userAccount: UserAccount = {
      id: userCredential.user.uid,
      photoUrl: providerData?.photoURL || null,
      lastLogin: serverTimestamp(),
      tenantId: userCredential.user.tenantId,
      emailAddress: userCredential.user.email,
    };

    if (firstName) userAccount.firstName = firstName;
    if (lastName) userAccount.lastName = lastName;

    await saveOne(userAccount);
    return;
  } catch (err) {
    throw err;
  }
}

function getOne(id: string): Observable<UserAccount> {
  const docRef = doc(db, `userAccounts/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAll(tenantId: string): Observable<UserAccount[]> {
  const collectionRef = collection(db, "userAccounts");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //convert timestamps to isostring
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      //sort by lastName alphabetically
      collection.sort((a, b) =>
        a.lastName?.toLowerCase().localeCompare(b.lastName?.toLowerCase())
      );
      return collection;
    })
  );
}

function getAllByEmailAddress(
  tenantId: string,
  emailAddress: string
): Observable<UserAccount[]> {
  const collectionRef = collection(db, "userAccounts");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("emailAddress", "==", emailAddress),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //convert timestamps to isostring
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      //sort by lastName alphabetically
      collection.sort((a, b) =>
        a.lastName?.toLowerCase().localeCompare(b.lastName?.toLowerCase())
      );
      return collection;
    })
  );
}

// Uplaods file and returns the file url
async function getOneByEmailAddress(
  tenantId: string,
  brandId: string,
  emailAddress: string
): Promise<UserAccount> {
  try {
    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //get signed url from firebase function
    const apiResponse = await client.get(
      `user-accounts/by-email-address/${emailAddress}/${brandId}`
    );

    const userAccount: UserAccount = apiResponse?.data;

    return userAccount;
  } catch (err) {
    throw err;
  }
}

async function saveOne(userAccount: UserAccount): Promise<void> {
  try {
    if (userAccount.deleted == undefined) userAccount.deleted = false;

    //get existing user document
    let existingUser: UserAccount;

    try {
      existingUser = await getOne(userAccount.id).pipe(first()).toPromise();
    } catch (err) {}

    if (!existingUser) {
      userAccount.createdDate = serverTimestamp();
      userAccount.createdByUserAccountId = auth.currentUser.uid;
    } else {
      userAccount.updatedDate = serverTimestamp();
      userAccount.updatedByUserAccountId = auth.currentUser.uid;
    }

    let docRef = doc(db, `userAccounts/${userAccount.id}`);
    await setDoc(docRef, userAccount, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

// function updateOne(registeredUser: UserAccount): Observable<UserAccount> {
//   const url = `${this.api}/user-account/${registeredUser.id}`;
//   return this.http.put<UserAccount>(url, registeredUser);
// }

// function disableAccountAccess(userAccountId: string): Observable<UserAccount> {
//   const url = `${this.api}/user-account/disable/${userAccountId}`;
//   return this.http.put<UserAccount>(url, {});
// }

// function enableAccountAccess(userAccountId: string): Observable<UserAccount> {
//   const url = `${this.api}/user-account/enable/${userAccountId}`;
//   return this.http.put<UserAccount>(url, {});
// }

const UserAccountService = {
  saveOne,
  saveOneWithCredential,
  getOne,
  getAll,
  getAllByEmailAddress,
  getOneByEmailAddress,
  // updateOne,
  // disableAccountAccess,
  // enableAccountAccess,
};

export default UserAccountService;
