import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { useForm, Controller } from "react-hook-form";
import { firstValueFrom } from "rxjs";
import { Spinner } from "flowbite-react";
import { Event } from "../../models/event";
import { auth } from "../../firebase/firebase";
import { HiPencilAlt, HiPlus } from "react-icons/hi";
import { Group } from "../../models/group";
import { Experience } from "../../models/experience";
import ExperienceService from "../../services/experience.service";
import { DateTime } from "luxon";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  calendarOutline,
  closeOutline,
  time,
  timeOutline,
} from "ionicons/icons";
import { Timeframe } from "../../models/timeframe";
import DataTransformationService from "../../services/data-transformation.service";

const SelectTimeframeButtonAndModal: FC<{
  timeframe: Timeframe;
  setTimeframe: (timeframe: Timeframe) => void;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ timeframe, setTimeframe, setErrorData, setErrorModalIsOpen }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { register, handleSubmit, setValue, control, watch } = useForm({
    defaultValues: {
      startTime: null,
      endTime: null,
    },
  });
  const brand = useBrandContext();

  useEffect(() => {
    // Get the experience data if the modal is open
    async function getData() {
      if (isOpen) {
        setIsLoading(true);
        updateFormValues(timeframe);
        setIsLoading(false);
      }
      return;
    }

    getData().catch((err) => {
      setIsLoading(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    });
  }, [isOpen, timeframe]);

  // Update the form values when the experience is loaded
  function updateFormValues(timeframe: Timeframe) {
    setValue("startTime", timeframe.startTime || DateTime.now().toISO());
    setValue("endTime", timeframe.endTime || DateTime.now().toISO());
  }

  async function onSubmit(formData: Timeframe) {
    try {
      setTimeframe(formData);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      <IonButton onClick={() => setIsOpen(true)}>
        <IonIcon slot="start" icon={calendarOutline} />
        <span className="hidden-md-down">
          {DataTransformationService.convertToLuxonFormat(
            timeframe.startTime,
            "LLL d, y"
          )}{" "}
          -{" "}
          {DataTransformationService.convertToLuxonFormat(
            timeframe.endTime,
            "LLL d, y"
          )}
        </span>
      </IonButton>

      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              <strong>Select a Timeframe</strong>
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)} color="primary">
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {isLoading ? (
            <Spinner color="success" />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList>
                <IonItemDivider>Quick Timeframe Selections</IonItemDivider>
                <IonItem>
                  <IonLabel>Last 7 Days</IonLabel>
                  <IonButton
                    fill="solid"
                    onClick={() => {
                      onSubmit({
                        startTime: DateTime.now()
                          .startOf("day")
                          .minus({ days: 6 })
                          .toISO(),
                        endTime: DateTime.now().endOf("day").toISO(),
                      });
                    }}
                  >
                    7 Days
                  </IonButton>
                </IonItem>
                <IonItem>
                  <IonLabel>Last 14 Days</IonLabel>
                  <IonButton
                    fill="solid"
                    onClick={() => {
                      onSubmit({
                        startTime: DateTime.now()
                          .startOf("day")
                          .minus({ days: 13 })
                          .toISO(),
                        endTime: DateTime.now().endOf("day").toISO(),
                      });
                    }}
                  >
                    14 Days
                  </IonButton>
                </IonItem>
                <IonItem>
                  <IonLabel>Last 30 Days</IonLabel>
                  <IonButton
                    fill="solid"
                    onClick={() => {
                      onSubmit({
                        startTime: DateTime.now()
                          .startOf("day")
                          .minus({ days: 29 })
                          .toISO(),
                        endTime: DateTime.now().endOf("day").toISO(),
                      });
                    }}
                  >
                    30 Days
                  </IonButton>
                </IonItem>
                <IonItem>
                  <IonLabel>Last 60 Days</IonLabel>
                  <IonButton
                    fill="solid"
                    onClick={() => {
                      onSubmit({
                        startTime: DateTime.now()
                          .startOf("day")
                          .minus({ days: 59 })
                          .toISO(),
                        endTime: DateTime.now().endOf("day").toISO(),
                      });
                    }}
                  >
                    60 Days
                  </IonButton>
                </IonItem>
                <IonItem>
                  <IonLabel>Last 90 Days</IonLabel>
                  <IonButton
                    fill="solid"
                    onClick={() => {
                      onSubmit({
                        startTime: DateTime.now()
                          .startOf("day")
                          .minus({ days: 89 })
                          .toISO(),
                        endTime: DateTime.now().endOf("day").toISO(),
                      });
                    }}
                  >
                    90 Days
                  </IonButton>
                </IonItem>

                <IonItemDivider>Custom Timeframe Selection</IonItemDivider>
                <IonItem>
                  <IonLabel>Start</IonLabel>
                  <Controller
                    control={control}
                    name="startTime"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          {/* Date and Time selection buttons */}
                          <IonDatetimeButton datetime="datetime_startTime">
                            {!value && (
                              <>
                                <IonIcon
                                  icon={calendarOutline}
                                  slot="date-target"
                                ></IonIcon>
                                <IonIcon
                                  icon={timeOutline}
                                  slot="time-target"
                                ></IonIcon>
                              </>
                            )}
                          </IonDatetimeButton>

                          {/* Date and Time selection pop-over */}
                          <IonPopover keepContentsMounted={true}>
                            <IonDatetime
                              id="datetime_startTime"
                              max={DateTime.now().endOf("day").toISO()}
                              min={DateTime.now()
                                .minus({ years: 20 })
                                .startOf("day")
                                .toISO()}
                              onIonChange={(e) => onChange(e.detail.value)}
                              value={value}
                            ></IonDatetime>
                          </IonPopover>
                        </>
                      );
                    }}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel>End</IonLabel>
                  <Controller
                    control={control}
                    name="endTime"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          {/* Date and Time selection buttons */}
                          <IonDatetimeButton datetime="datetime_endTime">
                            {!value && (
                              <>
                                <IonIcon
                                  icon={calendarOutline}
                                  slot="date-target"
                                ></IonIcon>
                                <IonIcon
                                  icon={timeOutline}
                                  slot="time-target"
                                ></IonIcon>
                              </>
                            )}
                          </IonDatetimeButton>

                          {/* Date and Time selection pop-over */}
                          <IonPopover keepContentsMounted={true}>
                            <IonDatetime
                              id="datetime_endTime"
                              max={DateTime.now().endOf("day").toISO()}
                              min={DateTime.now()
                                .minus({ years: 20 })
                                .startOf("day")
                                .toISO()}
                              onIonChange={(e) => onChange(e.detail.value)}
                              value={value}
                            ></IonDatetime>
                          </IonPopover>
                        </>
                      );
                    }}
                  />
                </IonItem>
              </IonList>

              <div className="ion-padding">
                <IonButton type="submit" color="primary" disabled={isSaving}>
                  Select
                  {isSaving && (
                    <span className="ml-2">
                      <Spinner
                        color={"success"}
                        size="sm"
                        aria-label="Selecting..."
                      />
                    </span>
                  )}
                </IonButton>
                <IonButton
                  color="danger"
                  fill="clear"
                  onClick={() => setIsOpen(false)}
                  disabled={isSaving}
                >
                  Cancel
                </IonButton>
              </div>
            </form>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default SelectTimeframeButtonAndModal;
