import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { combineLatest, firstValueFrom } from "rxjs";
import { Button, ListGroup, Modal } from "flowbite-react";
import { auth } from "../../firebase/firebase";
import { HiPlus } from "react-icons/hi";
import { Video } from "../../models/video";
import VideoService from "../../services/video.service";
import { IonButton } from "@ionic/react";

const SelectVideoButtonAndModal: FC<{
  buttonCopy?: string | undefined;
  selection: (video: Video) => void;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ buttonCopy, selection, setErrorData, setErrorModalIsOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const brand = useBrandContext();

  const [videos, setVideos] = useState<Video[]>([]);

  useEffect(() => {
    if (isOpen) {
      const subscription = combineLatest([
        VideoService.getAllByBrand(auth.tenantId, brand.id),
      ]).subscribe({
        next: ([videos]) => {
          setVideos(videos);
        },
        error: (err) => {
          setErrorData(err);
          setErrorModalIsOpen(true);
          throw err;
        },
        complete: () => console.info("complete"),
      });

      return () => subscription.unsubscribe();
    }
    return undefined;
  }, [isOpen]);

  function onSelect(id: any) {
    selection(id);
    setIsOpen(false);
  }

  return (
    <>
      <IonButton size="small" color="success" onClick={() => setIsOpen(true)}>
        <HiPlus className="mr-1 h-3 w-3" />
        {buttonCopy ? buttonCopy : "Select a Video"}
      </IonButton>

      <Modal onClose={() => setIsOpen(false)} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Select a Video</strong>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "60vh" }}>
          {videos && videos.length > 0 ? (
            <div className="flex justify-center">
              <ListGroup className="w-full">
                {videos.map((video) => (
                  <ListGroup.Item
                    key={video.id}
                    onClick={() => onSelect(video)}
                  >
                    <img
                      src={video.videoThumbnailUrl}
                      className="w-32 h-32 mr-4"
                    />
                    {video.videoName}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          ) : (
            <p>No videos found.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <IonButton
            size="small"
            color="danger"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </IonButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectVideoButtonAndModal;
