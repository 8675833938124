import { createContext, useContext } from "react";
import { UserAccountTenantPermission } from "../models/user-account-tenant-permission";

export const UserAccountTenantPermissionsContext = createContext<
  UserAccountTenantPermission[] | undefined
>(undefined);

export function useUserAccountTenantPermissionsContext() {
  const context = useContext(UserAccountTenantPermissionsContext);
  return context;
}
