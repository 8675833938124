import { createContext, useContext } from "react";
import { UserAccountBrandPermission } from "../models/user-account-brand-permission";

export const UserAccountBrandPermissionsContext = createContext<
  UserAccountBrandPermission[] | undefined
>(undefined);

export function useUserAccountBrandPermissionsContext() {
  const context = useContext(UserAccountBrandPermissionsContext);
  return context;
}
