/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, type FC, useState } from "react";
import ErrorModal from "../../components/modals/error-modal";

import { useBrandContext } from "../../context/brand.context";
import { Subscription, combineLatest } from "rxjs";
import { useParams } from "react-router-dom";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import SpinnerCentered from "../../components/SpinnerCentered";
import Breadcrumbs from "../../components/Breadcrumbs";
import EditSetButtonAndModal from "../../components/modals/edit-set-button-and-modal";
import { Set } from "../../models/set";
import SetService from "../../services/set.service";

const SetPage: FC = function () {
  const brand = useBrandContext();
  const { setId } = useParams<{ setId: string }>();

  // Error Message States
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [set, setSet] = useState<Set>();

  useEffect(() => {
    const subscription: Subscription = combineLatest([
      SetService.getOne(setId),
    ]).subscribe({
      next: ([set]) => {
        setSet(set);
        setIsLoading(false);
      },
      error: (err) => {
        setIsLoading(false);
        setErrorData(err);
        setErrorModalIsOpen(true);
        throw err;
      },
      complete: () => console.info("complete"),
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>{set?.setName}</IonTitle>
          <IonButtons slot="end">
            <EditSetButtonAndModal
              buttonCopy={"Edit Set"}
              setId={setId}
              setErrorData={setErrorData}
              setErrorModalIsOpen={setErrorModalIsOpen}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{set?.setName}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {isLoading ? (
          <SpinnerCentered />
        ) : (
          <>
            <Breadcrumbs
              data={[
                { name: "Sets", path: `/brand/${brand.id}/sets` },
                { name: set?.setName },
              ]}
            ></Breadcrumbs>

            <p className="ion-padding">Coming soon.</p>
          </>
        )}

        <ErrorModal
          errorData={errorData}
          errorModalIsOpen={errorModalIsOpen}
          setErrorModalIsOpen={setErrorModalIsOpen}
        />
      </IonContent>
    </IonPage>
  );
};

export default SetPage;
