/* eslint-disable jsx-a11y/anchor-is-valid */
import { IonButton, IonPage } from "@ionic/react";
import { Button, Card, Checkbox, Label, TextInput } from "flowbite-react";
import type { FC } from "react";

const ForgotPasswordPage: FC = function () {
  return (
    <IonPage>
      <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
        <a href="/" className="my-6 flex items-center gap-x-1 lg:my-0">
          <img
            alt="Drawbridge&trade; Dashboard Manager"
            src="/drawbridge-assets/drawbridge-dashboard-manager-logo.png"
            className="max-h-10"
          />
        </a>
        <Card className="w-full lg:max-w-[640px] lg:[&>*]:w-full lg:[&>*]:p-16">
          <h1 className="text-2xl font-bold dark:text-white md:text-3xl">
            Forgot your password?
          </h1>
          <p className="mb-3 text-gray-500 dark:text-gray-300">
            Type in your email and we will send you a link to reset your
            pasword!
          </p>
          <form>
            <div className="mb-6 flex flex-col gap-y-3">
              <Label htmlFor="email">Your email</Label>
              <TextInput
                id="email"
                name="email"
                placeholder="name@company.com"
                type="email"
              />
            </div>
            <div>
              <IonButton type="submit" className="w-full lg:w-auto">
                Reset password
              </IonButton>
            </div>
          </form>
        </Card>
      </div>
    </IonPage>
  );
};

export default ForgotPasswordPage;
